/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import {
  LikeOutlined,
  LikeFilled,
  DislikeOutlined,
  DislikeFilled,
  CopyOutlined,
  SoundOutlined,
  SoundFilled,
  QuestionCircleOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import logo_pom from "../../assets/logo_pom.png";
import { Tooltip, Modal, Button, Select, Input } from "antd";
import "./chat.css";
import axios from "axios";

const { Option } = Select;
const { TextArea } = Input;

const DynamicActionIcons = ({
  isLastMessage,
  isAI,
  activeMsgDtls,
  animation,
  handleClick,
  message,
  companies,
  company_regNo,
  setAIPromptTemplate,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  console.log("DynamicActionIcons speaking-----", userInfo);

  const [isPaused, setIsPaused] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [voice, setVoice] = useState(null);
  const [pitch, setPitch] = useState(1);
  const [rate, setRate] = useState(userInfo?.speed || 1);
  const [volume, setVolume] = useState(userInfo?.volume || 1);
  const utteranceRef = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [textAreaContent, setTextAreaContent] = useState("");
  const [audio, setAudio] = useState(null);

  const synth = window.speechSynthesis;

  const initializeUtterance = (text) => {
    console.log("initializeUtterance", userInfo);
    const language = "en-US";
    const synth = window.speechSynthesis;
    const voices = synth.getVoices();
    const availableVoices = voices?.filter(({ lang }) => lang === language);
    const activeVoice =
      voices?.find(({ name }) => name.includes(userInfo?.voice)) || voices?.[0];

    const u = new SpeechSynthesisUtterance(text);
    utteranceRef.current = u;
    u.voice = activeVoice;
    u.pitch = pitch;
    u.rate = rate;
    u.volume = volume;
  };
  const streamTextToAudio = async (plainText, selectedElevenLabsVoiceId) => {
    const apiKey = 'sk_462d4e3995209a67961f78f2872578c92485205faf01bb19';
    const textData = { text: plainText };

    try {
      const response = await axios({
        method: 'post',
        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectedElevenLabsVoiceId || "onwK4e9ZLuTAKqWW03F9"}/stream`,
        headers: {
          'Content-Type': 'application/json',
          'xi-api-key': apiKey,
        },
        data: textData,
        responseType: 'blob',
      });

      const audioBlob = response.data;
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
      };
      setAudio(audio);
    } catch (error) {
      console.error('Error streaming audio:', error);
    }
  };
  const initializeVoices = () => {
    synth.addEventListener("voiceschanged", () => {
      const voices = synth.getVoices();
      setVoice(voices[0]);
    });
  };

  const contentMap = {
    goal: {
      newGoal: `Can you provide an analysis of my energy levels and mood based on my past check-ins?

 I would like to see: The average energy levels and mood over the recorded period. 

A graph plotting my energy levels and mood over time. An analysis of common factors or activities on the days where my energy levels and mood were high. 

What common factors boost my mood? Any tips for improving sleep quality? 
Please include any patterns or correlations you notice.
`,
      setNewGoal: `Can you provide an analysis of my work productivity based on my past check-ins? 

I would like to see: The average work productivity over the recorded period. 

A graph plotting my work productivity over time. 
An analysis of common factors or activities on the days where my work productivity was high.
`,
    },
    health: {
      energylevels: `Can you provide an analysis of my energy levels and mood based on my past check-ins?

 I would like to see: The average energy levels and mood over the recorded period. 

A graph plotting my energy levels and mood over time. An analysis of common factors or activities on the days where my energy levels and mood were high. 

What common factors boost my mood? Any tips for improving sleep quality? 
Please include any patterns or correlations you notice.
`,
      nutrition: `Can you provide an analysis of my nutrition based on my past check-ins? I would like to see: 

The average nutrition quality over the recorded period.

 A graph plotting my nutrition quality over time.

An analysis of common factors or activities on the days where my nutrition quality was high.

Specific examples of meals on days with good and bad nutrition quality from my logged entries.

What common factors boost my nutrition quality? Any tips for improving nutrition quality, including specific meal advice. 

Please include any patterns or correlations you notice. Make this all into a coherent script in a friendly, warm and interesting tone
`,
    },
  };

  const categoryOptions = [
    { value: "goal", label: "Set New Goal" },
    { value: "health", label: "Health" },
  ];

  const subCategoryOptions = {
    goal: [
      { value: "newGoal", label: "Goal 1" },
      {
        value: "setNewGoal",
        label: "Goal 2",
      },
    ],
    health: [
      { value: "energylevels", label: "Energy Level" },
      { value: "nutrition", label: "Nutrition" },
    ],
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
    setSubCategory(null);
    setTextAreaContent(""); // Clear the content when category changes
  };

  const handleSubCategoryChange = (value) => {
    setSubCategory(value);
    setTextAreaContent(contentMap[category][value] || ""); // Set the content based on selected category and subcategory
  };

  const handleTextAreaChange = (event) => {
    setTextAreaContent(event.target.value);
  };

  // if (!isLastMessage || !isAI) {
  //   return null;
  // }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const promptTemplate = textAreaContent;
    setCategory(null);
    setSubCategory(null);
    setTextAreaContent("");
    setIsModalVisible(false);
    setAIPromptTemplate(promptTemplate);
  };

  const handleCancel = () => {
    setCategory(null);
    setSubCategory(null);
    setTextAreaContent("");
    setIsModalVisible(false);
  };

  const icons = [
    {
      showIcon: true,
      condition: activeMsgDtls.like,
      FilledIcon: LikeFilled,
      OutlinedIcon: LikeOutlined,
      tooltip: "Good response",
      animationType: "like",
      handleClickType: "like",
    },
    {
      showIcon: true,
      condition: activeMsgDtls.dislike,
      FilledIcon: DislikeFilled,
      OutlinedIcon: DislikeOutlined,
      tooltip: "Bad response",
      animationType: "dislike",
      handleClickType: "dislike",
    },

    {
      showIcon: true,
      condition: speaking,
      FilledIcon: SoundFilled,
      OutlinedIcon: SoundOutlined,
      tooltip: !speaking ? "Read Aloud" : "Stop",
      animationType: !speaking ? "stop-sound" : "Read Aloud",
      handleClickType: !speaking ? "stop-sound" : "Read Aloud",
      disabled: !speaking,
      onActionClick: (icon) => {
        if (isPaused) {
          synth.resume();
        } else {
          if (icon === "Stop") {
            setSpeaking(false);
            synth.cancel();
            if (audio) {
              audio.pause();
              audio.currentTime = 0; // Reset audio to the beginning
            }
          } else {
            if (userInfo.elevenLabsAudio) {
              streamTextToAudio(message, userInfo.elevenLabsAudioId)
            } else {
              initializeUtterance(message);
              synth.speak(utteranceRef.current);
            }
          }

          // setSpeaking(false)
        }
      },
    },
    {
      showIcon: true,
      FilledIcon: CopyOutlined,
      OutlinedIcon: CopyOutlined,
      tooltip: "Copy",
      animationType: "copy",
      handleClickType: "copy",
      onActionClick: (icon) => {
        const textMessage = document.getElementById(activeMsgDtls._id);
        const htmlContent = textMessage.innerHTML;
        const plainTextContent = textMessage.innerText;

        if (navigator.clipboard) {
          if (window.ClipboardItem) {
            // Full clipboard support (for browsers like Chrome)
            navigator.clipboard
              .write([
                new ClipboardItem({
                  "text/html": new Blob([htmlContent], { type: "text/html" }),
                  "text/plain": new Blob([plainTextContent], {
                    type: "text/plain",
                  }),
                }),
              ])
              .then(() => {
                console.log("Copied to clipboard successfully!");
              })
              .catch((err) => console.error("Failed to copy: ", err));
          } else {
            // Fallback for Safari or browsers without ClipboardItem support
            console.log("hello world");
            
            navigator.clipboard
              .writeText(plainTextContent)
              .then(() => {
                console.log("Plain text copied to clipboard successfully!");
              })
              .catch((err) => console.error("Failed to copy: ", err));
          }
        }
        // navigator.clipboard.writeText(message?.replace(/&nbsp;/gi, ""));
        handleClick(activeMsgDtls, "copy");
      },
    },
    {
      showIcon: true,
      condition: isLastMessage && isAI,
      FilledIcon: QuestionCircleOutlined,
      OutlinedIcon: QuestionCircleOutlined,
      tooltip: "Template",
      onActionClick: showModal,
    },
  ];
  useEffect(() => {
    const language = "en-US";
    const synth = window.speechSynthesis;
    const voices = synth.getVoices();
    console.log("voices", voices);
    const availableVoices = voices?.filter(({ lang }) => lang === language);
    const activeVoice =
      availableVoices?.find(({ name }) => name.includes("Google")) ||
      availableVoices?.find(({ name }) => name.includes("Luciana")) ||
      availableVoices?.[0];
    console.log("activeVoice", activeVoice);
    setVoice(activeVoice);
    synth.cancel();
  }, []);
  return (
    <div className="my-3 flex gap-4">
      {icons.map(
        (
          {
            showIcon,
            condition,
            FilledIcon,
            OutlinedIcon,
            tooltip,
            animationType,
            handleClickType,
            disabled,
            onActionClick,
          },
          index
        ) => (
          <div
            key={index}
            onClick={
              onActionClick
                ? () => {
                  if (tooltip === "Read Aloud") {
                    console.log("Inside if tooltip");
                    setSpeaking(true);
                  } else {
                    console.log("Inside else tooltip");
                    setSpeaking(false);
                  }
                  onActionClick(tooltip);
                }
                : () => handleClick(activeMsgDtls, handleClickType)
            }
            className={`cursor-pointer bg-[#f7f7f7] p-3  text-gray-500 rounded-2xl ${showIcon ? "flex" : "hidden"
              }`}
          >
            <Tooltip title={tooltip}>
              {condition !== undefined ? (
                condition ? (
                  <FilledIcon
                    className={`cursor-pointer ${animation === animationType ? "animate-bounce" : ""
                      }`}
                    disabled={disabled}
                  />
                ) : (
                  <OutlinedIcon
                    className={`cursor-pointer ${animation === animationType ? "animate-bounce" : ""
                      }`}
                    disabled={disabled}
                  />
                )
              ) : (
                <OutlinedIcon
                  className={`cursor-pointer ${animation === animationType ? "animate-bounce" : ""
                    }`}
                  disabled={disabled}
                />
              )}
            </Tooltip>
          </div>
        )
      )}

      <Modal
        className="custom-modal"
        title={
          <div
            style={{
              backgroundColor: "#ef6a36",
              padding: "10px 24px 10px 5px",
              margin: "-16px -24px 0 -24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo_pom}
              alt="Logo"
              align="middle"
              width="32px"
              height="32px"
              style={{ marginRight: "8px" }}
            />
            shkale Prompt Template
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false} // Hide the close icon
        cancelButtonProps={{ style: { color: "red", borderColor: "red" } }}
        okButtonProps={{
          style: { backgroundColor: "#ef6a36", borderColor: "#ef6a36" },
        }}
        footer={null}
      >
        <div className="modal-content">
          <div className="flex lg:flex-row flex-col gap-4">
            <div style={{ width: "100%" }}>
              <label>Category:</label>
              <Select
                placeholder="Select a category"
                onChange={handleCategoryChange}
                value={category}
                style={{ width: "100%", marginBottom: "16px" }}
              >
                {categoryOptions.map((cat) => (
                  <Option key={cat.value} value={cat.value}>
                    {cat.label}
                  </Option>
                ))}
              </Select>
            </div>
            {category && (
              <div style={{ width: "100%" }}>
                <label>Sub-category:</label>
                <Select
                  placeholder="Select a sub-category"
                  onChange={handleSubCategoryChange}
                  value={subCategory}
                  style={{ width: "100%" }}
                >
                  {subCategoryOptions[category].map((subCat) => (
                    <Option key={subCat.value} value={subCat.value}>
                      {subCat.label}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
          <label>Prompt Template:</label>
          <TextArea
            placeholder="Enter your content here"
            value={textAreaContent}
            onChange={handleTextAreaChange}
            rows={4}
            style={{ width: "100%", marginTop: "16px" }}
          />
        </div>
        <div>
          <div className="modal-footer">
            {/* <Button
            key="cancel"
            onClick={handleCancel}
            style={{ color: "red", borderColor: "red" }}
          >
            Cancel
          </Button> */}
            <Button
              key="ok"
              type="primary"
              onClick={handleOk}
              style={{ backgroundColor: "#ef6a36", borderColor: "#ef6a36" }}
            >
              Execute Template
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DynamicActionIcons;
