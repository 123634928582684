import React, { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  console.log("ModalProvider");
  const [signupLogin, setSignupLogin] = useState("");
  const [open, setOpen] = useState(false);

  const showSignUpModal = (component) => {
    console.log("showSignUpModal");
    setOpen(true);
    setSignupLogin(component);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{ open, signupLogin, showSignUpModal, handleCancel }}
    >
      {children}
    </ModalContext.Provider>
  );
};
