import { Input, Select, Space, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../utils/baseUrl";
import "./UserList.css";
import Header from "../../../../Components/Header/Header";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Search } = Input;

function UserList() {
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [isApiRender, setIsApiRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");


  const onSelectChange = async (value, selectedObj) => {
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}/admin/user/${value}/${selectedObj?.id}`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
    });
    setStatusUpdated((previousSate) => !previousSate);
  };

  const onRoleChange = async (value, selectedObj) => {
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}/admin/user/update_role`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
      data: { userId: selectedObj?.id, role: value }
    });
    setStatusUpdated((previousSate) => !previousSate);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text) => <a>{text}</a>,
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a className="text-blue-600">{text}</a>,
      ellipsis: true,
      fixed: "left",
      sorter: {
        compare: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: {
        compare: (a, b) => a.role.toLowerCase().localeCompare(b.role.toLowerCase()),
      },
    },
    {
      title: "Status",
      dataIndex: "user_state",
      key: "user_state",
      render: (text) => (
        <div
          className={`px-2 py-1 border text-center w-contain ${text === "Active"
            ? "border-green-600 text-green-600"
            : text === "Blocked"
              ? "border-red-600 text-red-600"
              : "border-gray-600 text-gray-600"
            }`}
        >
          {text.toUpperCase()}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.user_state.toLowerCase().localeCompare(b.user_state.toLowerCase()),
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, record) => {
        return (<div className="flex gap-4">
          <Space size="middle">
            <Select
              onChange={(e) => onSelectChange(e, item)}
              defaultValue={record.user_state}>
              <Select.Option value="activate">Active</Select.Option>
              <Select.Option value="block">Blocked</Select.Option>
            </Select>
          </Space>
          <Space size="middle">
            <Select
              onChange={(e) => onRoleChange(e, item)}
              defaultValue={record.role}>
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
          </Space>
        </div>
        );
      }
    },
  ];

  const data = userList.map((item, index) => {
    return {
      ...item,
      key: index,
      sno: index + 1,
      action: item,
    };
  });
  useEffect(() => {
    getUsersList();
  }, [statusUpdated, isApiRender]);

  const getUsersList = async () => {
    const { data } = await axios({
      method: "GET",
      url: `${baseUrl}/admin/user`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
    });
    setUserList(data.data);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onChange = (e) => {
    setUserObj({ ...userObj, [e.target.name]: e.target.value });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUserObj({})
  };

  const onSubmitClick = async () => {
    setIsModalOpen(false);
    const suffixUrl = "/chat-api/signup"
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}${suffixUrl}`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
      data: { ...userObj }
    });

    if (data.status === 200) {
      setIsApiRender(prevStatus => !prevStatus)
      setUserObj({})
    } else {
      toastMessage("warning")
    }
  };

  const toastMessage = (message) => {
    if (message == "warning") {
      toast.warn(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }
  const onInputChange = (e) => {
    const filteredData = data.filter(obj => obj.name?.toLowerCase().includes(e.target.value?.toLowerCase()))
    setInputSearchData(e.target.value)
    setFilteredData(filteredData)
  }
  const filterizedData = inputSearchData ? filteredData : data

  return (
    <div className="p-4">
      <Header title="Users" />

      <div className="flex justify-end gap-4 text-white items-center">
        <h2 className="font-semibold text-xl"></h2>
        <Search placeholder="Search for User Name" className='w-[50%] lg:w-[20%] ' onChange={onInputChange} />
        <button className=" border px-4 py-2 border-green-600 text-green-600 my-4 hover:text-green-700 hover:border-green-700"
          onClick={() => showModal()}>
          Add New User
        </button>
      </div>
      <Table
        columns={columns}
        dataSource={filterizedData}
        className="admin-user-list-table"
        pagination={{
          position: ["bottomRight"],
          className: "table-pagination-footer",
        }}
        scroll={{
          x: "max-content",
          y: document.documentElement.scrollHeight * 0.6,
        }}
      />
      {isModalOpen && <CustomModal
        title={`User Creation`}
        modalData={[
          {
            fieldTag: Input,
            formItemData: {
              name: "name",
              label: "Name",
              rules: [
                {
                  required: userObj?.name ? false : true,
                  message: "Please Enter your Name!",
                }
              ]
            },
            inputFieldData: {
              name: "name",
              placeholder: "Name",
              defaultValue: userObj?.name || "",
              onChange: onChange
            }
          },
          {
            fieldTag: Input,
            formItemData: {
              name: "email",
              label: "Email",
              rules: [
                {
                  required: userObj?.email ? false : true,
                  message: "Please Enter your Email!",
                }
              ]
            },
            inputFieldData: {
              name: "email",
              placeholder: "Email",
              defaultValue: userObj?.email || "",
              onChange: onChange
            }
          },
          {
            fieldTag: Input,
            formItemData: {
              label: "Password",
              name: "password",
              rules: [
                {
                  required: userObj?.password ? false : true,
                  message: "Please Enter your Password!",
                }
              ]
            },
            inputFieldData: {
              name: "password",
              placeholder: "Password",
              type: "password",
              defaultValue: userObj?.password || "",
              onChange: onChange,
            }
          }
        ]}
        buttonText={"Submit"}
        isModalOpen={isModalOpen}
        onSubmitClick={onSubmitClick}
        handleCancel={handleCancel}
      />}
      <ToastContainer />
    </div>
  );
}

export default UserList;
