import React, { useState, useEffect } from "react";
import { Button, Slider } from "antd";
import axios from "axios";
import VoiceDropdown from "../../Components/VoiceDropdown/VoiceDropdown";
import "./VoiceSettings.css"; // Import the CSS file
import { baseUrl } from "../../utils/baseUrl";

const VoiceSettings = (props) => {
  const text = ` Shkale (pronounced to rhyme with “kale”) my AI journal assistant. The name “Shkale” comes from the Irish language word “scéil” which means “story” in english. The spelling is different to allow non irish speakers pronounce it properly. 
`;
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const [selectedVoice, setSelectedVoice] = useState(props.defaultVoice);

  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(userInfo?.voice || null);
  const [pitch, setPitch] = useState(1);
  const [rate, setRate] = useState(userInfo?.speed || 1);
  const [volume, setVolume] = useState(userInfo?.volume || 0.7);

  useEffect(() => {
    console.log("useEffect Voicesettings");
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    setUtterance(u);

    synth.addEventListener("voiceschanged", () => {
      const synthVoices = window.speechSynthesis.getVoices();
      const selectedVoiceData = synthVoices.find(
        (voicedata) => voicedata.name === voice
      );
      console.log("selectedVoiceData", selectedVoiceData);

      setVoice(selectedVoiceData);
      //   const voices = synth.getVoices();

      //   //   const filterVoice = voices.find(
      //   //     (voice) => voice.name === props.defaultVoice
      //   //   );
      //   setVoice(voices[0]);
    });

    return () => {
      synth.cancel();
      synth.removeEventListener("voiceschanged", () => {
        setVoice(null);
      });
    };
  }, []);

  const updateConfiguration = async (values) => {
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}/chat-api/saveConfigurations`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
      data: {
        token: token,
        ...values,
      },
    });
    localStorage.setItem("userInfo", JSON.stringify(data.data));
  };
  const handlePlay = (tone = voice, speed = rate, sound = volume) => {
    console.log("handlePlay", tone, speed, sound, voice);
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    } else {
      utterance.voice = tone;
      utterance.pitch = pitch;
      utterance.rate = speed;
      utterance.volume = sound;
      synth.speak(utterance);
    }
    setIsPaused(false);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    setIsPaused(false);
    synth.cancel();
  };

  const handleRateChange = (value) => {
    updateConfiguration({ speed: value });
    setRate(parseFloat(value));
  };

  const handleVolumeChange = (value) => {
    updateConfiguration({ volume: volume });
    setVolume(parseFloat(value));
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <VoiceDropdown
          selectedVoice={selectedVoice}
          setSelectedVoice={(audio) => {
            console.log("audio", audio);
            setSelectedVoice(audio);
            setVoice(audio);
            updateConfiguration({ voice: audio.name });
            //  handlePlay(audio)
          }}
        />
        <div className="label-input-pair">
          <label>Speed:</label>
          <Slider
            min={0.5}
            max={2}
            step={0.1}
            value={rate}
            onChange={handleRateChange}
          />
        </div>
        <div className="label-input-pair">
          <label>Volume:</label>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      </div>

      <div className="button-group">
        <Button
          type="primary"
          onClick={() => {
            console.log("Onclick opg handleplay", voice);
            handlePlay();
          }}
          style={{ backgroundColor: "#168900", borderColor: "#168900" }}
        >
          {isPaused ? "Resume" : "Play"}
        </Button>
        <Button onClick={handleStop}>Stop</Button>
      </div>
    </div>
  );
};

export default VoiceSettings;
