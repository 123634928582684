import React, { useState } from 'react'
import auth from '../../routers/auth';
import { CloseOutlined, DownloadOutlined, MenuFoldOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { listUserConversationsApi, setActiveConversationId, setReduxIsFavoriteJournal } from '../../Store/reducers/shkaleChatSlice';
import { baseUrl } from '../../utils/baseUrl';
import logout from "../../assets/logout.svg";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { adminMenuItems, shkaleMenuItems } from '../constant';
import MenuItem from '../../pages/Chat/MenuItem';
import { Spin } from 'antd';
import ModelDropdown from '../ModelDropdown/ModelDropdown';
import mainJournal from "../../assets/mainJournal.png";
import fav_mainJournal from "../../assets/fav_mainJournal.png";
import MediaQuery from 'react-responsive';


function Header(props) {
    const token = localStorage.getItem("token");
    const [open, setOpen] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const createConversation = async () => {
        try {
            const { data } = await axios({
                method: "POST",
                url: `${baseUrl}/chat-api/create-conversation`,
                headers: {
                    "Content-Type": "application/json", // Example of a header
                    authtoken: token,
                },
            });
            dispatch(listUserConversationsApi(token));
            dispatch(setActiveConversationId(data));
            dispatch(setReduxIsFavoriteJournal(false));
            navigate(`/shkale/chat/${data}`, { replace: true });
        } catch (error) {
            console.error("Error sending message:", error);
            if (error?.response?.data?.message === "Token is invalid!") {
                auth.logout();
            }
        }
    };
    const { activeModel = "",
        handleModelChange = () => { },
        messages = "",
        updateFavoriteJournal = "",
        downloadChatHistory = () => { },
        handleIconClick = () => { },
        uploadHistory = () => { },
        setFileInput = "",
        isFavoriteJournal,
        title,
        uploadLoading
    } = props

    return (
        <header>
            <div className=" h-16 border-b border-gray-300 flex flex-row justify-between items-center">
                <div className=" pl-6 font-medium">
                    {title ? title :

                        <ModelDropdown
                            activeModel={activeModel}
                            handleModelChange={(e) => handleModelChange(e)}
                        />}
                </div>

                {!title && <div className="flex-row gap-4 p-4 ml-auto hidden lg:flex">
                    {messages.length > 0 ? (
                        <div
                            className="cursor-pointer"
                            onClick={() => updateFavoriteJournal()}
                        >
                            {" "}
                            {isFavoriteJournal ? (
                                <img
                                    src={fav_mainJournal}
                                    alt="Logo"
                                    align="middle"
                                    width="32px"
                                    height="32px"
                                    className=""
                                />
                            ) : (
                                <img
                                    src={mainJournal}
                                    alt="Logo"
                                    align="middle"
                                    width="32px"
                                    height="32px"
                                    className=""
                                />
                            )}
                        </div>
                    ) : null}
                    {messages.length > 0 ? (
                        <div className="border border-gray-300 p-2 rounded-lg shadow-lg shadow-gray-500/50 cursor-pointer">
                            <DownloadOutlined
                                className="text-xl text-[#6b7280]"
                                onClick={() => downloadChatHistory()}
                            />
                        </div>
                    ) : (
                        <div>
                            {uploadLoading ? (
                                <Spin tip="Loading..."></Spin>
                            ) : (
                                <div className="border border-gray-300 p-2 rounded-lg shadow-lg shadow-gray-500/50">
                                    <UploadOutlined
                                        className="text-xl text-[#6b7280] cursor-pointer"
                                        onClick={() => handleIconClick()}
                                    />
                                </div>
                            )}

                            <input
                                type="file"
                                style={{ display: "none" }}
                                accept=".json"
                                onChange={uploadHistory}
                                ref={(input) => setFileInput(input)}
                            />
                        </div>
                    )}
                </div>}
                <MediaQuery maxWidth={991.98}>
                    <div className="flex-row gap-4 p-4 lg:flex">
                        <div
                            className="flex items-center lg:hidden"
                            onClick={() => {
                                const openMenu = open;
                                setOpen(!openMenu);
                            }}
                        >
                            {open ?
                                <CloseOutlined className="text-3xl" />
                                :
                                <MenuFoldOutlined className="text-3xl" />
                            }
                        </div>
                    </div>
                    <div
                        id="myModal"
                        className={`hansel-modal my-4 ${open && "openModal"}`}
                    >
                        <div className="w-full h-full">
                            <div className="h-[64px] flex justify-end items-center border-b-2 border-light-border bg-[white]">
                                <CloseOutlined
                                    className="text-3xl pr-4"
                                    onClick={() => {
                                        const openMenu = open;
                                        setOpen(!openMenu);
                                    }}
                                />
                            </div>
                            <aside className="flex-1 flex flex-col h-[calc(100%-64px)]">
                                {/* Header */}
                                {/* Scrollable Content */}
                                {/* <div className="flex-1 overflow-y-auto px-4">
                                    <ChatHistory />
                                </div> */}
                                {/* Footer */}
                                <div className="flex-none border-light-border bg-[white] p-4">
                                    <div className="flex-1 overflow-y-auto">
                                        {shkaleMenuItems.map((item) => {
                                            let url = item.path
                                            return <MenuItem
                                                key={item.id}
                                                icon={item.icon}
                                                text={item.text}
                                                isActive={window.location.pathname.includes(item.path)}
                                                isPro={item.isPro}
                                                onClick={() => navigate(url)}
                                            />
                                        })}
                                        {userInfo.role === "admin" && <> <div className="my-4 text-gray-600 ">Admin Menu</div>
                                            {userInfo.role === "admin" && adminMenuItems.map((item) => {
                                                let url = item.path;
                                                if (userInfo.role === "admin" && item.id == "Admin") {
                                                    return;
                                                }
                                                return (
                                                    <MenuItem
                                                        key={item.id}
                                                        icon={item.icon}
                                                        text={item.text}
                                                        isActive={window.location.pathname.includes(item.path)}
                                                        isPro={item.isPro}
                                                        onClick={() => navigate(url)}
                                                    />
                                                );
                                            })}
                                        </>}
                                    </div>
                                    <div
                                        className="flex flex-row border-t-2 mt-2 pt-3 justify-between cursor-pointer"
                                        onClick={() => {
                                            auth.logout();
                                        }}
                                    >
                                        <div className="text-xl">Log out</div>
                                        <div>
                                            <img
                                                src={logout}
                                                alt="logout"
                                                align="middle"
                                                width="28px"
                                                height="28px"
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        </header >
    )
}

export default Header