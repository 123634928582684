import React, { useState, useEffect } from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './VoiceDropdown.css'; // Import the CSS file

const VoiceDropdown = (props) => {
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(props.selectedVoice);

  useEffect(() => {
    const fetchVoices = () => {
      const synthVoices = window.speechSynthesis.getVoices();
      setVoices(synthVoices);
      if (synthVoices.length > 0) {
        const selectedVoiceData = synthVoices.find((voice) => voice.name === props.selectedVoice);
        setSelectedVoice(selectedVoiceData?.name);
      }
    };

    fetchVoices();
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = fetchVoices;
    }
  }, []);

  const handleMenuClick = (e) => {
    console.log("handleMenuClick",e)
    setSelectedVoice(e.key);
    const selectedVoiceData = voices.find((voice) => voice.name === e.key);
    props.setSelectedVoice(selectedVoiceData)
  };

  const items = voices.map((voice) => ({
    key: voice.name,
    label: voice.name,
  }));

  const menu = (
    <Menu
      items={items}
      onClick={handleMenuClick}
      className="custom-menu"
    />
  );

  return (
    <div className="">
      <label>
        Voice: &nbsp;
        <Dropdown
          overlayClassName="custom-model-dropdown"
          overlay={menu}
        >
          <a className="custom-border" onClick={(e) => e.preventDefault()}>
            <Space>
              {selectedVoice}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </label>
    </div>
  );
};

export default VoiceDropdown;
