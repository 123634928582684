import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';
import error from './middleware/error';
import api from './middleware/api';

const initialState =window.INITIAL_STATE
const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), api, error],
  devTools: true,
});

export default store;
