import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import menu_icon from "../../assets/menu-icon.png";
import { Link } from "react-scroll";
import { ModalContext } from "../ModalCOntext";

const Navbar = () => {
  const { showSignUpModal } = useContext(ModalContext);

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 50 ? setSticky(true) : setSticky(false);
    });
  }, []);

  const [mobileMenu, setMobileMenu] = useState(false);
  const toggleMenu = () => {
    mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
  };

  return (
    <nav className={`container ${sticky ? "dark-nav" : ""}`}>
      <img src={logo} alt="" className="logo" />
      <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
        <li>
          <Link to="hero" smooth={true} offset={0} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="features" smooth={true} offset={-260} duration={500}>
            Features
          </Link>
        </li>
        <li>
          <Link to="pricing" smooth={true} offset={-150} duration={500}>
            Pricing
          </Link>
        </li>
        <li>
          <a
            href="#"
            
            onClick={() => {
              console.log("showSignUpModal_______");
              showSignUpModal("SignUp");
            }}
            className="btn !bg-[#228B22] !text-white"
          >
            Get Started For Free
          </a>
        </li>
      </ul>
      <img src={menu_icon} alt="" className="menu-icon" onClick={toggleMenu} />
    </nav>
  );
};

export default Navbar;
