import React from 'react';

const MenuItem = ({ icon: Icon, text, isActive, isPro, onClick }) => {
  return (
    <div
      className={`p-4 rounded-xl flex flex-row gap-4 ${isActive ? 'bg-[#1e233a] text-white' : ''} cursor-pointer`}
      onClick={onClick}
    >
      <div>
        <Icon className="text-[#168900] text-xl" />
      </div>
      <div>{text}</div>
      {isPro && (
        <div className="bg-[#4c3126] py-0.5 px-2.5 rounded-full">
          <span className="text-[#e5551b] text-sm">PRO</span>
        </div>
      )}
    </div>
  );
};

export default MenuItem;