import React from 'react'
import './Calender.css'
import Header from '../../Components/Header/Header'

function Calender() {
    return (
        <div className="flex flex-col flex-1 bg-[white] mt-1 lg:ml-4 lg:mr-0 lg:mr-4 rounded-2xl lg:rounded-none lg:rounded-s-2xl  border-r border-gray-300  ">
            <Header title="Calender"/>
            <div className='p-4 text-center'>Calender</div>
        </div>
    )
}

export default Calender