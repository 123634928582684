import React, { useEffect, useState } from "react";
import "./PromptList.css";
import { DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Dropdown,
  Space,
  Table,
  Input,
  Select,
  Modal,
  Button,
} from "antd";
import axios from "axios";
import { baseUrl } from "../../../../utils/baseUrl";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import Header from "../../../../Components/Header/Header";
const { confirm } = Modal;

function PromptList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const [promptObj, setPromptObj] = useState({});
  const [versionContentObj, setVersionContentObj] = useState({});
  const [versionContent, setVersionContent] = useState("");
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const [isPromptEdited, setIsPromptEdited] = useState(false);
  const [isApiRender, setIsApiRender] = useState(false);
  const [updatedVersion, setUpdatedVersion] = useState("");

  // edit modal
  const showModal = (selectedPromptObj) => {
    setIsModalOpen(true);
    if (selectedPromptObj) {
      setIsPromptEdited(true)
      setPromptObj({
        title: selectedPromptObj.title, content: selectedPromptObj.content,
        accessType: selectedPromptObj.accessType,
        promptType: selectedPromptObj.promptType,
        active_version: selectedPromptObj.active_version || selectedPromptObj.version,
        id: selectedPromptObj.id
      });
    }
  };

  const onSubmitClick = async () => {
    setIsModalOpen(false);
    const suffixUrl = isPromptEdited ? `edit/${promptObj.id}` : "add"
    isPromptEdited && delete promptObj.id
    isPromptEdited && delete promptObj.content_history
    const { data } = await axios({
      method: isPromptEdited ? "PUT" : "POST",
      url: `${baseUrl}/admin/prompt/${suffixUrl}`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
      data: { ...promptObj }
    });
    if (data.status === 200) {
      setIsApiRender(prevStatus => !prevStatus)
      setPromptObj({})
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsPromptEdited(false)
    setIsVersionModalOpen(false)
    setPromptObj({})
  };

  const onChange = (e) => {
    setPromptObj({ ...promptObj, [e.target.name]: e.target.value });
  };

  const onDeletePromptRowClick = (obj) => {
    confirm({
      title: `Are you sure you want to delete this "${obj.title}"`,
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        const { data } = await axios({
          method: "DELETE",
          url: `${baseUrl}/admin/prompt/delete/${obj.id}`,
          headers: {
            "Content-Type": "application/json",
            authtoken: token,
          },
          data: { ...promptObj }
        });
        if (data.status === 200) {
          setIsApiRender(prevStatus => !prevStatus)
        }
      },
      onCancel() {
      },
    });
  }

  const getPromptData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/admin/prompts`, {
        headers: {
          authtoken: localStorage.getItem("token"),
        },
      });
      setData(response.data.data);
    } catch (error) {
      console.log("error into the getting the prompt");
    }
  };


  useEffect(() => {
    getPromptData();
  }, [isApiRender]);

  const versionEditClick = (updatedContent, version, obj) => {
    let arr = JSON.parse(JSON.stringify(obj.content_history))
    const exists = arr.some(
      (entry) => entry.content === obj.content && entry.version === obj.version
    );

    if (!exists) {
      arr.push({ content: obj.content, version: obj.version });
    }
    obj.content_history = arr
    setVersionContent(updatedContent)
    setUpdatedVersion(version)
    setVersionContentObj({ ...obj })
    setIsVersionModalOpen(true)
  }

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text) => <a>{text}</a>,
      width: 70,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <a className="text-blue-600">{text}</a>,
      ellipsis: true,
      fixed: "left"
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (obj) => {
        const version = obj.active_version || obj.version
        const updatedContent = obj.content_history?.find(obj => obj.version == version)?.content || obj.content
        return <a>{updatedContent?.substring(0, 40)} </a>
      },
      ellipsis: true
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      width: 100,
      render: (obj) => {
        const version = obj.active_version || obj.version
        const updatedContent = obj.content_history?.find(obj => obj.version == version)?.content || obj.content
        return <a>{version} <EditOutlined
          className="text-green-600"
          onClick={() => versionEditClick(updatedContent, version, obj)}
        /></a>
      }
    },
    {
      title: "Prompt-type",
      dataIndex: "promptType",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      className: 'text-wrap',
      render: (item) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (<p onClick={() => {
                    showModal(item);
                  }}>
                    <EditOutlined
                      className="text-green-600"
                    /> Edit
                  </p>
                  ),
                },
                {
                  key: "2",
                  label: (<p onClick={() => {
                    onDeletePromptRowClick(item);
                  }}>
                    <DeleteOutlined
                      className="mt-2 text-red-600"
                    /> Delete
                  </p>
                  ),
                },
              ],
            }}
          >
            <DownOutlined />
          </Dropdown>
        </Space>

      ),
    },
  ];

  const updatedData = data.map((obj, index) => {
    let arr = JSON.parse(JSON.stringify(obj.content_history))
    const exists = arr.some(
      (entry) => entry.content === obj.content && entry.version === obj.version
    );

    if (!exists) {
      arr.push({ content: obj.content, version: obj.version });
    }
    obj.content_history = arr
    return {
      ...obj,
      content: obj,
      sno: index + 1,
      key: index,
      version: obj,
      action: obj
    }
  })

  //version modal functions
  const onVersionSelect = (selectedVersion) => {
    setUpdatedVersion(selectedVersion)
    const updatedContent = versionContentObj.content_history?.find(
      obj => obj.version == selectedVersion || "")
    setVersionContent(updatedContent.content);
  }

  const onVersionUpdateClick = async () => {
    const { data } = await axios({
      method: "PUT",
      url: `${baseUrl}/admin/prompt/set-version/${versionContentObj.id}`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
      data: { version: updatedVersion, active: true }
    });
    if (data.status === 200) {
      setIsApiRender(prevStatus => !prevStatus)
      setIsVersionModalOpen(false)
      setVersionContent("")
      setUpdatedVersion(""),
        setVersionContentObj({})
    }
  }

  const onVersionModalClose = () => {
    setIsVersionModalOpen(false)
    setVersionContent("")
    setUpdatedVersion("")
    setVersionContentObj({})
  }

  return (
    <div className="p-4">
      <Header title="Prompt" />
      <div className="flex justify-between text-white items-center">
        <h2 className="font-semibold text-xl"></h2>
        <button className=" border px-4 py-2 border-green-600 text-green-600 my-4 hover:text-green-700 hover:border-green-700"
          onClick={() => showModal()}
        >
          Add New Prompt
        </button>
      </div>
      <Table
        columns={columns}
        dataSource={updatedData}
        className="admin-prompt-list-table"
        pagination={{
          position: ["bottomRight"],
          className: "table-pagination-footer",
        }}
        scroll={{
          x: "max-content",
          y: document.documentElement.scrollHeight * 0.6,
        }}
      />
      {isModalOpen && <CustomModal
        title={`Prompt ${isPromptEdited ? "Updation" : "Creation"}`}
        modalData={[{
          fieldTag: Input,
          formItemData: {
            name: "title",
            label: "Title",
            rules: [
              {
                required: promptObj?.title ? false : true,
                message: "Please Enter your Title!",
              }
            ]
          },
          inputFieldData: {
            name: "title",
            placeholder: "Title",
            defaultValue: promptObj?.title || "",
            onChange: onChange
          }
        },
        {
          fieldTag: Input.TextArea,
          formItemData: {
            label: "Content",
            name: "content",
            rules: [
              {
                required: promptObj?.content ? false : true,
                message: "Please Enter your Content!",
              }
            ]
          },
          inputFieldData: {
            name: "content",
            placeholder: "content for prompt",
            defaultValue: promptObj?.content || "",
            onChange: onChange,
            autoSize: { minRows: 2, maxRows: 6 }
          }
        },
        {
          fieldTag: Select,
          fieldName: "select",
          formItemData: {
            label: "Access Type",
            name: "accessType",
            rules: [
              {
                required: promptObj.accessType ? false : true,
                message: "Please Selct Access Type!",
              }
            ]
          },
          selectFieldData: {
            defaultValue: promptObj.accessType,
            placeholder: "Select",
            onChange: (e) =>
              onChange({ target: { name: "accessType", value: e } })
          },
          optionsFieldData: {
            fieldTag: Select.Option,
            options: [{
              value: "AdminPrompt",
              label: "Admin Prompt"
            },
            {
              value: "UserPrompt",
              label: "User Prompt"
            }
            ]
          }
        },
        {
          fieldTag: Select,
          fieldName: "select",
          formItemData: {
            label: "Prompt Type",
            name: "promptType",
            rules: [
              {
                required: promptObj.promptType ? false : true,
                message: "Please Selct Prompt Type!",
              }
            ]
          },
          selectFieldData: {
            defaultValue: promptObj.promptType,
            placeholder: "Select",
            onChange: (e) =>
              onChange({ target: { name: "promptType", value: e } })
          },
          optionsFieldData: {
            fieldTag: Select.Option,
            options: [
              {
                value: "System Prompt",
                label: "System Prompt"
              },
              {
                value: "Specialization Prompt",
                label: "Specialization Prompt"
              }
            ]
          }
        },
        {
          fieldTag: Input,
          formItemData: {
            name: "active_version",
            label: "Active Version"
          },
          inputFieldData: {
            name: "version",
            placeholder: "version",
            disabled: true,
            defaultValue: promptObj?.active_version || "",
          }
        }
        ]}
        buttonText={isPromptEdited ? "Update" : "Submit"}
        isModalOpen={isModalOpen}
        onSubmitClick={onSubmitClick}
        handleCancel={handleCancel}
      />}
      {isVersionModalOpen && <Modal
        title={"Prompt Version Updation"}
        open={isVersionModalOpen}
        footer={null}
        onCancel={onVersionModalClose}
      >
        <p>Content</p>
        <div className="prompt-version-wrapper">
          <p>{versionContent}</p>
        </div>
        <div>
          <p>Version</p>
          <Space >
            <Select
              defaultValue={updatedVersion}
              style={{
                width: 120,
              }}
              onChange={(e) => onVersionSelect(e)}
              options={versionContentObj?.content_history.map((obj) => {
                return {
                  value: obj.version,
                  label: obj.version
                }
              })}
            >
            </Select>
          </Space>
        </div>
        <Button type="primary" htmlType="submit" className="mt-4" onClick={onVersionUpdateClick}>
          Update
        </Button>
      </Modal>}
    </div>
  );
}

export default PromptList;
