import { Button, Card, DatePicker, Empty, Pagination, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header';
import { baseUrl } from '../../utils/baseUrl';
import axios from "axios";
import { ReloadOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import CustomModal from '../../Components/CustomModal/CustomModal';
import moment from 'moment';
const { Search } = Input;


function CheckIns() {
    const token = localStorage.getItem("token");
    const [checkinList, setCheckinList] = useState([])
    const [filteredCheckinList, setFilteredCheckinList] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [prompt, setPrompt] = useState("Extract check-ins from the chat history in provided JSON after 18 Oct format if not details fields are not there skip that ")
    const [date, setDate] = useState({
        start_date: null,
        end_date: null
    })
    const [inputSearchData, setInputSearchData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const fetchAllCheckins = async () => {
        try {
            const { data } = await axios({
                method: "POST",
                url: `${baseUrl}/chat-api/checkin`,
                headers: {
                    "Content-Type": "application/json",
                    authtoken: token,
                },
                data: date
            });
            const latestObj = data?.[0] || {}
            const latestDate = moment(latestObj.date).format('D')
            const latestMonth = moment(latestObj.date).format('MMM')

            setPrompt(prompt.replace("18 Oct", `${latestDate} ${latestMonth}`))
            setCheckinList(data || [])

        } catch (error) {
            console.error("Error into the fetchAllCheckins")
        }
    }

    const onResetBtnClick = async () => {
        try {
            setLoading(true)
            const checkinLatest = checkinList[0] || {}
            const { data } = await axios({
                method: "POST",
                url: `${baseUrl}/chat-api/hard-refresh-checkin`,
                headers: {
                    authtoken: token
                },
                data: {
                    prompt,
                    latest_date: checkinLatest.date
                }
            });
            if (data) {
                setLoading(false)
                setIsModalOpen(false)
                fetchAllCheckins()
            }

        } catch (error) {
            setLoading(false)
            console.error("Error into the fetchAllCheckins")
        }
    }

    useEffect(() => {
        fetchAllCheckins()
    }, [date.start_date, date.end_date])

    const onChange = (date1, dateString, name) => {
        setDate({ ...date, [name]: dateString })
    };
    const onInputChange = (e) => {
        const filteredData = checkinList.filter(obj => obj.title?.toLowerCase().includes(e.target.value?.toLowerCase()))
        setInputSearchData(e.target.value)

        setFilteredCheckinList(filteredData)
    }
    const checkinListSearchFilterData = filteredCheckinList ? filteredCheckinList : checkinList

    const startIndex = (currentPage - 1) * 9;
    const endIndex = startIndex + 9;
    const checkinListData = JSON.parse(JSON.stringify(checkinListSearchFilterData)).slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="flex flex-col flex-1 bg-[white] mt-1 lg:ml-4 lg:mr-0 lg:mr-4  rounded-2xl lg:rounded-none lg:rounded-s-2xl  border-r border-gray-300  ">
            <Header title="Check Ins" />
            <div className="p-8 overflow-y-auto">
                <div className='flex flex-wrap gap-8'>
                    <Search placeholder="Search for checkins" className='w-full lg:w-[30%] ' onChange={onInputChange} />
                    <div className='flex gap-8'>
                        <Space direction="vertical ">
                            <DatePicker onChange={(date, dateString) => onChange(date, dateString, "start_date")} />
                        </Space>
                        <Space direction="vertical">
                            <DatePicker onChange={(date, dateString) => onChange(date, dateString, "end_date")} />
                        </Space>
                    </div>
                    <Button icon={<ReloadOutlined />} className='ml-auto mr-6'
                        loading={loading} type="primary" onClick={() => setIsModalOpen(true)}>
                        Hard Reset
                    </Button>
                </div>
                <div className='flex flex-wrap justify-stretch gap-3 mt-8'>
                    {checkinListData.map((checkinObj, index) => {
                        const details = checkinObj?.details
                        return <Card extra={`${checkinObj.date} ${checkinObj.time}`}
                            hoverable bordered={false} title={checkinObj.title} key={`check-in-${index}`}
                            style={{ border: "1px solid lightgreen" }} className='w-full lg:w-[30%] md:w-[48%] '>
                            <div className='max-h-64 overflow-y-auto'>
                                {Object.keys(details).map((key) => (
                                    <div key={key}>
                                        <h3 className="text-yellow-500">{key}</h3>
                                        {Array.isArray(details[key]) ? (
                                            details[key].map((item, index) => (
                                                <div key={index} style={{ marginBottom: "15px" }}>
                                                    {typeof item === "object" && item !== null ? (
                                                        <div>
                                                            {Object.keys(item).map((subKey) =>
                                                                Array.isArray(item[subKey]) ? (
                                                                    <div key={subKey}>
                                                                        <h4>{subKey.charAt(0)?.toUpperCase() + subKey.slice(1)}</h4> {/* Render nested array title */}
                                                                        <ul>
                                                                            {item[subKey].map((subItem, subIndex) => (
                                                                                <li key={subIndex} >{subItem}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : (
                                                                    <p key={subKey} >
                                                                        <span className="text-slave-400">
                                                                            {subKey.charAt(0).toUpperCase() + subKey.slice(1)}
                                                                        </span>
                                                                        : <b className="text-blue-400">{item[subKey]}</b>
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <p >{item}</p>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-blue-400">{details[key]}</p>
                                        )}
                                    </div>
                                ))}


                            </div>
                        </Card>
                    })}
                    {!checkinListData?.length && <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 60
                        }}
                        description={
                            <Typography.Text>
                                No Checkins Found
                            </Typography.Text>
                        }
                        className='m-auto mt-8'
                    >
                    </Empty>}
                </div>
                {checkinListData?.length ? <Pagination align="center"
                    pageSize={9} total={inputSearchData ? checkinListData?.length : checkinList?.length}
                    current={currentPage}
                    onChange={handlePageChange}
                    className="table-pagination-footer mt-4"
                /> : null}

                {isModalOpen && <CustomModal
                    title={`Hard Reset`}
                    modalData={[
                        {
                            fieldTag: Input,
                            formItemData: {
                                name: "prompt",
                                label: "Prompt",
                                rules: [
                                    {
                                        required: prompt ? false : true,
                                        message: "Please Enter your Name!",
                                    }
                                ]
                            },
                            inputFieldData: {
                                name: "prompt",
                                placeholder: "Content Prompt",
                                defaultValue: prompt || "",
                                onChange: (e) => setPrompt(e.target.value)
                            }
                        }]}

                    buttonText={"Submit"}
                    isModalOpen={isModalOpen}
                    onSubmitClick={onResetBtnClick}
                    buttonProps={loading}
                    handleCancel={() => setIsModalOpen(false)}
                />}
            </div>
        </div>
    )
}

export default CheckIns