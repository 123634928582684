import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <p></p>

      <ul>
        <li className='text-sm text-blue-500'><a href="/terms-and-conditons" target='_blank'>Terms of Services</a></li>
        <li className='text-sm text-blue-500'><a href="/privacy-policy" target='_blank'>Privacy Policy</a></li>
      </ul>
    </div>
  )
}

export default Footer
