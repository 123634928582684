import React from 'react';
import auth from './auth';
import { Navigate, Outlet } from 'react-router-dom';


const PublicRoute = () => {
  return auth.isAuthenticated ? <Navigate to="/shkale/chat" /> : <Outlet />;


};

export default PublicRoute;
