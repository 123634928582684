import React from "react";
import {
  DownOutlined,
  InfoCircleOutlined,
  WechatWorkOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { Dropdown, Space, Switch } from "antd";
import {models} from "../constant";

import "./ModelDropdown.css";

const ModelDropdown = ({ activeModel, handleModelChange }) => {
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const handleMenuClick = (e) => {
    handleModelChange(e.key);
  };

  const items = [
    {
      key: "1",
      type: "group",
      label: (
        <div className="flex justify-between items-center">
          <span>Model</span>
          <span className="cursor-pointer">
            <InfoCircleOutlined />
          </span>
        </div>
      ),
      children: models.map((model) => {
        return ({
          key: model.key,
          disabled: model.disabled,
          label: (
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div>
                  <img
                    src={model.icon}
                    alt="Logo"
                    align="middle"
                    width="32px"
                    height="32px"
                    className=""
                  />
                </div>
                <div className="flex flex-col">
                  <span>{model.name}</span>
                  <span className="text-sm text-gray-400">
                    {model.description}
                  </span>
                </div>
              </div>
              <div>
                {activeModel === model.name ? <CheckCircleFilled /> : null}
              </div>
            </div>
          ),
        })
      }),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      type: "group",
      label: "",
      disabled: true,
      children: [
        {
          key: "TemporaryChat",
          disabled: true,
          label: (
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="text-lg">
                  <WechatWorkOutlined />
                </div>
                <div className="flex flex-col">
                  <span>Temporary Chat</span>
                </div>
              </div>
              <div>
                <Switch onChange={onChange} />
              </div>
            </div>
          ),
        },
      ],
    },
  ];
  return (
    <Dropdown
      overlayClassName="custom-model-dropdown"
      menu={{ items, onClick: handleMenuClick }}
    >
      <a className="custom-border" onClick={(e) => e.preventDefault()}>
        <Space>
          {activeModel}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

export default ModelDropdown;
