import { Button, Checkbox, Modal } from 'antd'
import React, { useState } from 'react'
import './ChatMenuModal.css'
function getTimeOfDay() {
  const currentHour = new Date().getHours();
  
  if (currentHour >= 5 && currentHour < 12) {
      return "morning";
  } else if (currentHour >= 12 && currentHour < 17) {
      return "afternoon";
  } else if (currentHour >= 17 && currentHour < 21) {
      return "evening";
  } else {
      return "night";
  }
}
const menuItems = [
  {color : "#168901", label : `${getTimeOfDay()} check-in`},
  {
    color: "#FFF885",
    label: "Mindfulness Check-In"
  },
  {
    color: "#FB4F4F",
    label: "Goal Setting Check-In"
  },
  {
    color: "#AC79FF",
    label: "Food/Diet Diary"
  },
  {
    color: "lightgreen",
    label: "Get to know me"
  },
  {
    color: "#FBEBCA",
    label: "General Entry"
  }
]
function ChatMenuModal({ onSelectMenuBtnClick, isModalOpen, onCancel }) {
  const [selectedMenuTitle, setSelectedMenuTitle] = useState("General Entry")
  const [checkedItems, setCheckedItems] = useState(
    menuItems.map((_, index) => index === 5)
  );

  const onChange = (index, title) => {
    const updatedCheckedItems = checkedItems.map((checked, i) =>
      i === index ? !checked : false
    );
    setSelectedMenuTitle(title)
    setCheckedItems(updatedCheckedItems);
  };

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      width={340}
      onCancel={onCancel}
      className="chat-menu-select-modal">
      <h3 className='chat-menu-title'>Select journal Entry Type</h3>
      {menuItems.map((obj, index) => {
        return <div key={obj.label} className='chat-selection-menu-wrapper'>
          <div className='check-box-color-div' style={{ background: obj.color }}></div>
          <p className='chat-selection-menu-title'>{obj.label}</p>
          <Checkbox className='chat-menu-checkbox' checked={checkedItems[index]}
            onChange={() => onChange(index, obj.label)}>
          </Checkbox>
        </div>
      })}
      <Button type="primary" className='w-[100%] mt-7'
        size="large" onClick={() => onSelectMenuBtnClick(selectedMenuTitle)}>Select</Button>

    </Modal>
  )
}

export default ChatMenuModal