import {
  AudioMutedOutlined,
  AudioOutlined,
  CloseOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/baseUrl";

const VoiceAssistance = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isAIRecording, setIsAIRecording] = useState(false);
  const [status, setStatus] = useState("Welcome");
  const [chatMessages, setChatMessages] = useState([]);
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isPaused, setIsPaused] = useState(false); // Track pause state

  const { type } = useParams();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const navigate = useNavigate();

  const AUTH_TOKEN = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const conversationId = localStorage.getItem("mainJounralId");

  const setupRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { channelCount: 1, sampleRate: 16000 },
      });

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
        audioBitsPerSecond: 16000,
      });

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        try {
          const wavBlob = await convertWebMToWav(audioBlob);
          await sendAudioToServer(wavBlob);
        } catch (error) {
          console.error("Error converting audio:", error);
          setStatus("Error With Your Audio");
        }
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current = mediaRecorder;
      return true;
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setStatus("Could not access microphone");
      return false;
    }
  };

  // Convert WebM to WAV
  const convertWebMToWav = async (webmBlob) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const arrayBuffer = await webmBlob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const wavBlob = createWavBlob(audioBuffer);
    return wavBlob;
  };

  const createWavBlob = (audioBuffer) => {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const length = audioBuffer.length * numberOfChannels;
    const wavBuffer = new ArrayBuffer(44 + length * 2);
    const view = new DataView(wavBuffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + length * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numberOfChannels * 2, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, "data");
    view.setUint32(40, length * 2, true);

    const channels = [];
    for (let i = 0; i < numberOfChannels; i++) {
      channels.push(audioBuffer.getChannelData(i));
    }

    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, channels[channel][i]));
        view.setInt16(
          offset,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        );
        offset += 2;
      }
    }

    return new Blob([wavBuffer], { type: "audio/wav" });
  };

  const sendAudioToServer = async (wavBlob) => {
    try {
      if (!conversationId || !localStorage.getItem("mainJounralId")) {
        alert("Conversation id not present ");
        return;
      }
      const formData = new FormData();
      formData.append("file", wavBlob, "recording.wav");
      formData.append(
        "conversation_id",
        conversationId || localStorage.getItem("mainJounralId") || ""
      );
      formData.append("chat_model", "Gemini");
      formData.append("conversation_type", "None");
      formData.append("entry_type", type);

      setStatus("✨ Analyzing your input...");

      const response = await fetch(`${baseUrl}/chat-api/voice-chat`, {
        method: "POST",
        headers: { authtoken: `${AUTH_TOKEN}` },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      let currentText = "";
      let isPlaying = false;
      let bufferedChunk = ""; // Buffer for base64 strings
      setIsAIRecording(true)

      const playNextAudio = async () => {
        if (audioQueue.length === 0 || isPlaying) return;

        isPlaying = true;
        const audioData = audioQueue.shift();
        const audio = new Audio(`data:audio/mp3;base64,${audioData}`);
        setAudio(audio);

        audio.onended = () => {
          isPlaying = false;
          playNextAudio();
          setAudio(null);
          setIsAIRecording(false)
          setStatus("✨ AI is waiting for input...");
        };

        try {
          await audio.play();
        } catch (error) {
          console.error("Error playing audio:", error);
          isPlaying = false;
          playNextAudio();
        }
      };

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        bufferedChunk += chunk; // Append chunk to the buffer

        // Split the buffer by newline (if applicable)
        const lines = bufferedChunk.split("\n");
        bufferedChunk = lines.pop(); // Keep the incomplete part in the buffer

        for (const line of lines) {
          // Process each base64 string line
          if (line.trim() !== "") {
            function processData(input) {
              const match = input.match(/data:\s*"(.*?)"/);
              return match ? match[1] : input;
            }
            let updatedLine = processData(line);
            audioQueue.push(updatedLine);
            setAudioQueue([...audioQueue]);
            if (!isPlaying) playNextAudio();
          }
        }
      }

      setStatus("✨ AI speaking...");
    } catch (error) {
      console.error("Error sending audio:", error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { isUser: false, text: `Error: ${error.message}` },
      ]);
      setStatus("Error occurred");
    }
  };

  const toggleRecording = async () => {
    if (!isRecording) {
      if (!mediaRecorderRef.current) {
        const setup = await setupRecording();
        if (!setup) return;
      }

      mediaRecorderRef.current.start();
      setIsRecording(true);
      if (audio) {
        audio.pause(), setIsPaused(!isPaused);
      }
    } else {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setStatus("Processing...");
    }
  };
  const toggleAudio = () => {
    if (isPaused) {
      audio.play();
      setStatus("✨ AI speaking... ");
    } else {
      audio.pause();
      setStatus("✨ AI Speach Paused ");
    }
    setIsAIRecording(!isAIRecording)
    setIsRecording(false);
    setIsPaused(!isPaused);
  };
  console.log(isAIRecording && !isPaused, isAIRecording, !isPaused, "$$$$$$$$");

  return (
    <div
      className={`${!isAIRecording && !isPaused ? "bg-[#0f1021]" : "bg-[#0f0d1e]"
        } h-dvh text-center p-4 `}
    >
      <h1 className="text-white mt-6">Shkale Voice Chat Assistance</h1>
      <div
        id="chat-container"
        className="m-auto h-[60%]"
        onClick={
          audio
            ? () => {
              audio.pause(), setIsPaused(!isPaused);
            }
            : null
        }
      >
        {chatMessages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.isUser ? "user-message" : "ai-message"
              }`}
          >
            {message.text}
          </div>
        ))}
        {isAIRecording && !isPaused ? (
          <img
            src="/ai_voices.gif"
            width={"500px"}
            className="m-auto mt-[10%]"
          />
        ) : (
          <img
            src="/ai_loading.gif"
            className="m-auto mt-[6%]"
            width={"700px"}
          />
        )}
      </div>
      <div
        id="controls"
        className="flex gap-3 w-[400px] bg-white p-4 rounded-[48px] absolute bottom-[100px] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <div className="flex items-baseline	">
          <button
            onClick={toggleRecording}
            className={isRecording ? "recording" : ""}
          >
            {isRecording ? (
              <AudioOutlined className="text-3xl" />
            ) : (
              <AudioMutedOutlined className="text-3xl" />
            )}
          </button>
          <p id="status" className="ml-4 mb-0">
            {status}
          </p>
        </div>
        <button className="ml-auto">
          {audio ? (
            isPaused ? (
              <PlayCircleOutlined
                className="text-3xl text-green-500"
                onClick={() => toggleAudio()}
              />
            ) : (
              <PauseCircleOutlined
                className="text-3xl text-red-500"
                onClick={() => toggleAudio()}
              />
            )
          ) : null}
          <CloseOutlined
            onClick={() => navigate("/shkale/chat")}
            className="text-3xl ml-2"
          />{" "}
        </button>
      </div>
    </div>
  );
};

export default VoiceAssistance;
