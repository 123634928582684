import React from "react";
import { adminMenuItems, shkaleMenuItems } from "../constant";
import logo_pom from "../../assets/logo.png";
import logout from "../../assets/logout.svg";
import auth from "../../routers/auth";
import { Divider } from "antd";
import MenuItem from "../../pages/Chat/MenuItem";
import { useNavigate } from "react-router-dom";

function Menu() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <aside className="hidden lg:flex flex-col mt-4 p-4 w-64 text-white relative">
      <header className="w-full mb-6">
        <div className="relative inline-flex gap-2 items-center text-sm font-medium text-center ">
          <img src={logo_pom} alt="Logo" align="middle" className="" />
        </div>
      </header>
      <div className="flex-1 overflow-y-auto">
        {shkaleMenuItems.map((item) => {
          let url = item.path;
          if (userInfo.role === "admin" && item.id == "Admin") {
            return;
          }
          return (
            <MenuItem
              key={item.id}
              icon={item.icon}
              text={item.text}
              isActive={window.location.pathname.includes(item.path)}
              isPro={item.isPro}
              onClick={() => navigate(url)}
            />
          );
        })}
        {userInfo.role === "admin" && <div className="my-4 text-gray-600 ">Admin Menu</div>}
        {userInfo.role === "admin" && adminMenuItems.map((item) => {
          let url = item.path;
          if (userInfo.role === "admin" && item.id == "Admin") {
            return;
          }
          return (
            <MenuItem
              key={item.id}
              icon={item.icon}
              text={item.text}
              isActive={window.location.pathname.includes(item.path)}
              isPro={item.isPro}
              onClick={() => navigate(url)}
            />
          );
        })}
      </div>
      <footer className="p-4 w-full mt-auto">
        <Divider className="custom-divider" />
        <div
          className="flex flex-row justify-between cursor-pointer"
          onClick={() => {
            auth.logout();
          }}
        >
          <div className="text-xl">Log out</div>
          <div>
            <img
              src={logout}
              alt="logout"
              align="middle"
              width="28px"
              height="28px"
              className=""
            />
          </div>
        </div>
      </footer>
    </aside>
  );
}

export default Menu;
