// RoutesConfig.js
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Chat from "../pages/Chat/Chat";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Settings from "../pages/Settings/Settings";
import auth from './auth';
import Calender from "../pages/Calender/Calender";
import Trends from "../pages/Trends/Trends";
import AdminPrivateRoute from "./AdminPrivateRoute";
// import UserList from "../pages/Admin/pages/UserList/UserList";
import PromptList from "../pages/Admin/pages/PromptPage/PromptList";
import UserList from "../pages/Admin/pages/UserList/UserList";
import CheckIns from "../pages/CheckIns/CheckIns";
import PrivacyPolicy from "../Components/Footer/PrivacyPolicy";
import TermsCondition from "../Components/Footer/TermsCondition";
import VoiceAssistance from "../pages/Chat/VoiceAssistance";

const RoutesConfig = () => {
  return useRoutes([
    {
      path: "/",
      element: <PublicRoute />,
      children: [{ path: "/", element: <Home /> }],
    },
    {
      path: "/shkale",
      element: <PrivateRoute />,
      children: [
        { path: 'chat', element: <Chat /> },
        { path: "chat/:id", element: <Chat /> },
        { path: "checkins", element: <CheckIns /> },
        { path: "calender", element: <Calender /> },
        { path: "settings", element: <Settings /> },
        { path: "trends", element: <Trends /> }
      ]
    },
    {
      path: "/admin",
      element: <AdminPrivateRoute />,
      children: [
        { path: "users", element: <UserList /> },
        { path: "prompt", element: <PromptList /> },
      ]
    },
    {
      path: ":type/voice-assistance",
      element: auth.isAuthenticated ? <VoiceAssistance /> : <Navigate to="/" />,
    },
    {
      path: "/",
      element: <PublicRoute />,
      children: [{ path: "/privacy-policy", element: <PrivacyPolicy /> }],
    },
    {
      path: "/",
      element: <PublicRoute />,
      children: [{ path: "/terms-and-conditons", element: <TermsCondition /> }],
    },
    {
      path: "*",
      element: auth.isAuthenticated ? <Navigate to="/shkale/chat" /> : <Navigate to="/" />,
    },
  ]);
};

export default RoutesConfig;
