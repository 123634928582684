/* eslint-disable no-nested-ternary */
import React from 'react';
import auth from './auth';
import { Navigate, Outlet } from 'react-router-dom';
import Menu from '../Components/Menu/Menu';

const PrivateRoute = () => {
  return auth.isAuthenticated ?
    <div className='flex h-screen bg-[black]'>
      <Menu />
      <Outlet />
    </div>
    : <Navigate to="/" />;

};

export default PrivateRoute;
