import { Button, Form, Modal } from 'antd'
import React from 'react'

function CustomModal({ onSubmitClick, handleCancel, isModalOpen, modalData = [], title, buttonText, buttonProps }) {
    return (
        <Modal
            title={title}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                name="trigger"
                style={{
                    maxWidth: 600,
                }}
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFinish={onSubmitClick}
                autoComplete="off"
            >
                {modalData?.map((obj, index) => {
                    return <Form.Item
                        {...obj.formItemData}
                        key={`${title}_form_item_${index}`}>
                        {
                            obj.fieldName == "select" ?
                                <obj.fieldTag {...obj.selectFieldData} >
                                    {obj.optionsFieldData.options?.map((optionsObj, index) =>
                                        <obj.optionsFieldData.fieldTag value={optionsObj.value}
                                            key={`${title}_select_${index}`}>
                                            {optionsObj.label}
                                        </obj.optionsFieldData.fieldTag>)}
                                </obj.fieldTag>
                                : <obj.fieldTag {...obj.inputFieldData} />
                        }
                    </Form.Item>

                })}
                <Button type="primary" htmlType="submit" className="m-4" loading={buttonProps} >
                    {buttonText}
                </Button>
            </Form>
        </Modal>
    )
}

export default CustomModal
