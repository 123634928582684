import React, { useContext } from "react";
import { Modal } from "antd";
import "./Hero.css";
import { ArrowRightOutlined } from '@ant-design/icons';

import SignUpLogin from "../SignupSignIn/SignUpLogin";
import dark_arrow from "../../assets/dark-arrow.png";
import { ModalContext } from "../ModalCOntext";
const Hero = () => {
  const { open, signupLogin, showSignUpModal, handleCancel } =
    useContext(ModalContext);

  // const [signupLogin, setSignupLogin] = useState('');
  // const [open, setOpen] = useState(false);

  // const showSignUpModal = component => {
  //   setOpen(true);
  //   setSignupLogin(component);
  // };

  // const handleCancel = () => {
  //   setOpen(false);
  // };

  return (
    <div className="hero container">
      {open ? (
        <Modal
          open={open}
          footer={null}
          maskClosable
          destroyOnClose
          closable={false}
          width={"768px"}
          onCancel={handleCancel}
          className="signup-modal" // Add a custom class to the modal

        >
          <SignUpLogin signupLogin={signupLogin} />
        </Modal>
      ) : null}

      <div className="hero-text">
        <h1 style={{ fontSize: "45px" }}>
          Capture Your Thoughts <br />
          with AI-Powered Precision
        </h1>
        <p className="text-xl">
          Our AI Journal app helps you organise your <br />
          thoughts,track your progress, and gain insights <br />
          with advanced AI analysis
        </p>
        <button
          className="btn !bg-[#228B22] !text-white"
          style={{ boxShadow: "0px 3px 2px -2px black" }}
          onClick={() => showSignUpModal("SignUp")}
        >
          <div className="flex gap-4"><span>Get Started For Free</span> <ArrowRightOutlined /></div>
        </button>
      </div>
    </div>
  );
};

export default Hero;
