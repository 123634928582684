/* eslint-disable consistent-return */
import axios from "axios";

import { apiCallBegan } from "../api";
import auth from "../../routers/auth";
import { baseUrl } from "../../utils/baseUrl";


// eslint-disable-next-line consistent-return
const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) return next(action);
    const { url, method, data, onStart, onSuccess, onError, requestedId } =
      action.payload;
    if (onStart) {
      dispatch({
        type: onStart,
        requestedId,
        loadingRequire:
          data !== undefined && data.showLoading !== undefined
            ? data.showLoading
            : true,
      });
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.request({
        baseURL: `${baseUrl}/chat-api`,
        url,
        method,
        headers: {
          authtoken: token,
        },
        data: {},
        // data: { ...data, token },
      });
      console.log("response+++", response);

      dispatch({
        type: onSuccess,
        payload: response.data,
        requestedId,
        requestParameter: data,
      });
    } catch (error) {
      if (error?.response?.data?.message === "Token is invalid!") {
        auth.logout();
      }
      if (onError) {
        dispatch({
          type: onError,
          requestedId,
          payload: { error: error.message, ...data },
        });
      }
      dispatch({
        type: "SHOW_ERROR",
        requestedId,
        payload: { error: error.message },
      });
    }
  };

export default api;
