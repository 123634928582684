import React from 'react'

function PrivacyPolicy() {
    return (
        <div className='bg-[gray]'>
            <div className='p-8 m-auto border-[1px] text-center w-8/12 bg-[white]'>
                <div className="container mx-auto p-6 text-left">
                    <header className="text-center mb-12">
                        <h1 className="text-4xl font-bold mb-4">Privacy Policy for Skhale App</h1>
                        <p className="text-lg text-gray-600">Effective Date: {new Date().toDateString()}</p>
                        <p className="text-lg text-gray-600 mt-4">At Skhale, we prioritize your privacy and are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our app, website, or other services. Please take a moment to read this policy carefully.</p>
                    </header>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
                        <p className="text-gray-700 mb-2">We collect various types of information to provide and improve our services to you.</p>
                        <h3 className="text-xl font-semibold mb-2">A. Personal Information</h3>
                        <ul className="list-disc list-inside text-gray-700 mb-4">
                            <li>Account Information: Name, email address, phone number, profile picture, and other details provided when creating or managing an account.</li>
                            <li>Payment Information: If you make in-app purchases or subscribe to premium features, we may collect billing information such as credit card details or payment methods.</li>
                            <li>Location Information: If you grant permission, we collect information about your location for location-based features of the app.</li>
                        </ul>
                        <h3 className="text-xl font-semibold mb-2">B. Non-Personal Information</h3>
                        <ul className="list-disc list-inside text-gray-700 mb-4">
                            <li>Device Information: Type of device (smartphone, tablet), operating system version, device identifiers (e.g., IMEI), and browser type.</li>
                            <li>Usage Data: How you interact with the app, including app features you use, the time spent on the app, and user activities.</li>
                            <li>Log Data: Your IP address, device ID, browser type, pages visited, and the date and time of your visit.</li>
                        </ul>
                        <h3 className="text-xl font-semibold mb-2">C. Cookies and Tracking Technologies</h3>
                        <p className="text-gray-700 mb-2">We use cookies and similar technologies (like web beacons, pixels) to collect data on your usage of the app. This data helps us:</p>
                        <ul className="list-disc list-inside text-gray-700 mb-4">
                            <li>Analyze trends and improve app performance.</li>
                            <li>Provide personalized content and advertisements.</li>
                            <li>Ensure the security and functionality of the app.</li>
                        </ul>
                        <p className="text-gray-700">You can adjust your browser settings to disable cookies, but some features may not function properly.</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
                        <p className="text-gray-700 mb-2">We use the information we collect for several purposes, including:</p>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Providing Services: To operate and maintain our app, ensure its functionality, and offer personalized experiences.</li>
                            <li>Communicating with You: To respond to your queries, provide support, and send you app-related updates or promotional content (if you have opted in).</li>
                            <li>Improving User Experience: To analyze usage data, perform analytics, and enhance app features.</li>
                            <li>Marketing and Advertising: To send promotional materials or personalized advertisements based on your preferences, but only if you have consented to receive such communications.</li>
                            <li>Legal Compliance: To comply with applicable laws, regulations, and legal processes, including responding to lawful requests from authorities.</li>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">3. Sharing Your Information</h2>
                        <p className="text-gray-700 mb-2">We respect your privacy and will not sell, rent, or trade your personal information to third parties. However, we may share your data under the following circumstances:</p>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Service Providers: We may share data with trusted third-party service providers (e.g., payment processors, cloud storage providers, analytics tools) who help us deliver our services. These providers are contractually obligated to safeguard your data.</li>
                            <li>Legal Requirements: If required by law, regulation, or legal process, we may disclose your information to comply with judicial or governmental requests, or to protect the rights, property, or safety of Skhale, our users, or the public.</li>
                            <li>Business Transfers: If we undergo a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
                        <p className="text-gray-700 mb-2">We use industry-standard security measures to protect your information, including:</p>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Encryption: Secure Socket Layer (SSL) encryption to protect data during transmission.</li>
                            <li>Access Control: Restricting access to personal information to authorized personnel only.</li>
                            <li>Regular Audits: Conducting regular security audits to ensure the integrity of our systems.</li>
                        </ul>
                        <p className="text-gray-700">However, no method of data transmission or storage is 100% secure, and we cannot guarantee absolute security.</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
                        <p className="text-gray-700">We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy, such as providing services and complying with legal obligations. If you delete your account, we may retain certain information for backup, auditing, or legal purposes, but we will not use it for marketing or personal identification.</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
                        <p className="text-gray-700 mb-2">Depending on your location and the applicable laws (e.g., GDPR, CCPA), you may have the following rights regarding your personal data:</p>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Access: You can request a copy of the personal information we hold about you.</li>
                            <li>Correction: You can update or correct any inaccurate or incomplete personal information.</li>
                            <li>Deletion: You can request the deletion of your personal information, subject to any legal retention requirements.</li>
                            <li>Opt-Out: You can opt out of receiving marketing communications by adjusting your settings in the app or through an unsubscribe link in emails.</li>
                            <li>Data Portability: You may request that we transfer your data to another service provider, if feasible.</li>
                        </ul>
                        <p className="text-gray-700">To exercise your rights, please contact us at [Contact Information].</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">7. Children’s Privacy</h2>
                        <p className="text-gray-700">Skhale is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information as soon as possible.</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">8. Changes to This Privacy Policy</h2>
                        <p className="text-gray-700">We may update this Privacy Policy from time to time to reflect changes in our practices or to comply with legal requirements. Any changes will be posted on this page with an updated "Effective Date." We encourage you to review this policy periodically for updates.</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
                        <p className="text-gray-700 mb-2">If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</p>
                        <p className="text-gray-700">Email: [Your Email Address]</p>
                        <p className="text-gray-700">Address: [Your Business Address]</p>
                        <p className="text-gray-700">Phone: [Your Phone Number]</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">10. Jurisdiction and Governing Law</h2>
                        <p className="text-gray-700">This Privacy Policy is governed by the laws of [Your Jurisdiction]. By using the Skhale app, you consent to the jurisdiction and venue of courts in [Your Jurisdiction].</p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">11. California Privacy Rights (CCPA)</h2>
                        <p className="text-gray-700 mb-2">If you are a California resident, you have specific rights under the California Consumer Privacy Act (CCPA), including the right to request:</p>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>What personal data we collect about you</li>
                            <li>The purposes for which we use your personal data</li>
                            <li>The third parties with whom we share your personal data</li>
                        </ul>
                        <p className="text-gray-700">To make such requests or for more information about your rights, please contact us at [Contact Information].</p>
                    </section>
                </div>
            </div>
        </div>

    )
}

export default PrivacyPolicy