const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const auth = {
    isAuthenticated: userInfo && userInfo.token,
    authenticate(cb) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.isAuthenticated = userInfo && userInfo.token;
        setTimeout(cb, 100); // fake async
    },
    logout(cb) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        this.isAuthenticated = false;
        window.location.href='/'
    },
};

export default auth;