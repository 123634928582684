import React from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './VoiceDropdown.css'; // Import the CSS file

const elevenLabsAudioOptions = [
  {
    name: "Daniel",
    value: "onwK4e9ZLuTAKqWW03F9"
  },
  {
    name: "Expresso",
    value: "9xakBqZrP0Xmk5tZ2pqM"
  },
  {
    name: "Alexander Kensington",
    value: "mZ8K1MPRiT5wDQaasg3i"
  },
  {
    name: "Beatrice Wandsworthy",
    value: "tGBi6UOk2p83aJ0Vmcev"
  },
  {
    name: "Arnold (Legacy)",
    value: "VR6AewLTigWG4xSOukaG"
  },
  {
    name: "Dorothy (Legacy)",
    value: "ThT5KcBeYPX3keUQqHPh"
  },
  {
    name: "Fin (Legacy)",
    value: "D38z5RcWu1voky8WS1ja",
  },
  {
    name: "Jo : Middle aged British female",
    value: "L4so9SudEsIYzE9j4qlR"
  },
  {
    name: "Mina",
    value: "PlfGT4dz1lNsHEvyMRFQ"
  }
]
const ElevenLabsDropdown = (props) => {

  const { defaultVoice = "onwK4e9ZLuTAKqWW03F9" } = props
  const items = elevenLabsAudioOptions.map((voice) => ({
    key: voice.value,
    label: voice.name,
  }));

  const menu = (
    <Menu
      items={items}
      onClick={(e) => props.updateConfiguration({ elevenLabsAudioId: e.key })}
      className="custom-menu"
    />
  );

  return (
    <div className="">
      <p className='mb-4'>
        Select Eleven Labs Voice &nbsp;
      </p>
      <Dropdown
        overlayClassName="custom-model-dropdown"
        overlay={menu}
      >
        <a className="custom-border" onClick={(e) => e.preventDefault()}>
          <Space>
            {items?.find(obj => obj.key == defaultVoice)?.label}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default ElevenLabsDropdown;
