import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import auth from "../../routers/auth";
import {
  AudioOutlined,
  ArrowUpOutlined,
  AudioFilled,
  LoadingOutlined,
  DoubleLeftOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import {
  listUserConversationsApi,
  setReduxIsFavoriteJournal,
  setActiveConversationId,
} from "../../Store/reducers/shkaleChatSlice";
import Messages from "./Messages";
import "./chat.css";
import { baseUrl } from "../../utils/baseUrl";
import Header from "../../Components/Header/Header";
import ChatMenuModal from "./ChatMenuModal/ChatMenuModal";

const Chat = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const dispatch = useDispatch();

  const recognitionRef = useRef();
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  const [activeModel, setActiveModel] = useState(userInfo.aiModel);

  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [regenrateMsg, setRegenrateMsg] = useState(false);
  const [prevPrompt, setPrevPrompt] = useState("");
  const [prompt, setPrompt] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [isFavoriteJournal, setIsFavoriteJournal] = useState(false);
  const [activeConversation, setActiveConversation] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [stopwatch, setStopwatch] = useState(0.0);
  const [page, setPage] = useState(1);
  const [newMessage, setNewMessage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAutoReload, setIsAutoReload] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const chatContainerRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [selectedChatMenu, setSelectedChatMenu] = useState("");
  const [selectedChatStatus, setSelectedChatStatus] = useState(true);

  const [, setError] = useState(null);

  const audioLevels = [
    4, 2, 6, 8, 10, 8, 6, 3, 2, 1, 3, 2, 1, 4, 2, 5, 8, 10, 12, 8, 6, 2, 4, 4,
    2, 6, 8, 10, 8, 6, 3, 2, 1,
  ];
  const { conversationList } = useSelector((state) => state?.shkaleChat);

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer?.addEventListener("scroll", handleScroll);
    setCurrentDate(formatDate(new Date()));
    return () => {
      chatContainer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = 10;
    }
  };

  const handleOnRecord = () => {
    if (isActive) {
      recognitionRef.current?.stop();
      setIsActive(false);
      clearInterval(intervalRef.current);
      setStopwatch(0.0);
      return;
    }
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.onstart = function () {
      setIsActive(true);
      intervalRef.current = setInterval(() => {
        setStopwatch((prev) => prev + 0.1);
      }, 1000); // Update every second
    };
    recognitionRef.current.onstop = function () {
      setIsActive(false);
      clearInterval(intervalRef.current);
      setStopwatch(0.0);
    };
    recognitionRef.current.onend = function () {
      setIsActive(false);
      clearInterval(intervalRef.current);
      setStopwatch(0.0);
    };
    recognitionRef.current.onresult = async function (event) {
      const transcript = event.results[0][0].transcript;
      setPrompt(transcript);
    };
    recognitionRef.current.start();
  };
  const handleModelChange = (modelKey) => {
    // Update the active model or perform any other action
    setActiveModel(modelKey);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "long", // Full month name
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Use 12-hour time (AM/PM)
    };

    return date.toLocaleString("en-US", options);
  };

  const onSendMessage = async (msg, regenrate = false) => {
    setLoading(true);
    if (!regenrate)
      setMessages((prev) => [
        ...prev,
        { history: { type: "human", data: { content: msg } } },
      ]);
    setNewMessage(true);
    try {
      const result = await axios({
        method: "POST",
        url: `${baseUrl}/chat-api/send-message`,
        headers: {
          "Content-Type": "application/json",
          authtoken: token,
        },
        data: {
          token: token,
          conversation_id: activeConversation,
          input: msg.trim(),
          current_date_time: currentDate,
          chat_model: activeModel,
          entry_type: selectedChatMenu,
        },
      });
      // setActiveConversation(activeConversation);
      setIsFavoriteJournal(result.data?.isFavoriteJournal || false);
      if (activeConversation === "") {
        // dispatch(setActiveConversationId(result.data.mainJounralId));
        dispatch(
          setReduxIsFavoriteJournal(result.data?.isFavoriteJournal || false)
        );
        dispatch(listUserConversationsApi(token));
      }
      setMessages((prev) => [...prev, result.data]);
      setNewMessage(true);
    } catch (error) {
      console.error("Error sending message:", error);
      if (error?.response?.data?.message === "Token is invalid!") {
        auth.logout();
      } else {
        setRegenrateMsg(true);
      }
    } finally {
      setLoading(false);
    }
  };
  const regenrateResponse = () => {
    setRegenrateMsg(false);
    onSendMessage(prevPrompt, true);
  };
  const sendMessage = () => {
    if (prompt.trim() !== "") {
      onSendMessage(prompt);
      setPrompt("");
      setPrevPrompt(prompt);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Shift + Enter: Add a new line in the textarea
        e.preventDefault();
        const textarea = e.target;
        const cursorPosition = textarea.selectionStart;
        const value = textarea.value;
        textarea.value =
          value.substring(0, cursorPosition) +
          "\n" +
          value.substring(cursorPosition);
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1;
        setPrompt(textarea.value);
        // Update the prompt state with the new line
        handleInput(e); // Call handleInput to adjust the height
      } else {
        // Enter: Send the message
        e.preventDefault();
        if (!loading) {
          sendMessage();
        }
      }
    }
  };

  const handleInput = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto"; // Reset height to auto
    textarea.style.height = textarea.scrollHeight + "px"; // Set the height based on the scroll height
    const inputValue = textarea.value;
    setPrompt(inputValue);
    // Update the prompt state
  };
  // const createConversation = async () => {
  //   try {
  //     const { data } = await axios({
  //       method: "POST",
  //       url: `${baseUrl}/chat-api/create-conversation`,
  //       headers: {
  //         "Content-Type": "application/json", // Example of a header
  //         authtoken: token,
  //       },
  //     });
  //     dispatch(listUserConversationsApi(token));
  //     dispatch(setActiveConversationId(data));
  //     setIsFavoriteJournal(false);
  //     dispatch(setReduxIsFavoriteJournal(false));
  //     navigate(`/shkale/chat/${data}`, { replace: true });
  //   } catch (error) {
  //     console.error("Error sending message:", error);
  //     if (error?.response?.data?.message === "Token is invalid!") {
  //       auth.logout();
  //     }
  //   }
  // };
  const [fileInput, setFileInput] = useState(null);

  const handleIconClick = () => {
    if (fileInput) {
      fileInput.click();
    }
  };
  const uploadHistory = (event) => {
    setUploadLoading(true);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const history = JSON.parse(e.target.result);

          // Validate the history items
          const invalidItems = history.filter(
            (item) =>
              !(
                item.type === "human" ||
                (item.type === "ai" &&
                  item.data &&
                  typeof item.data.content === "string")
              )
          );

          if (invalidItems.length === 0) {
            // Add conversation_id to each valid history object
            const updatedHistory = history.map((item) => ({
              ...item,
              conversation_id: activeConversation,
            }));
            await uploadMessage(updatedHistory);

            setUploadLoading(false);
            // Process the updated history data as needed
          } else {
            setUploadLoading(false);
            console.error("Invalid history data:", invalidItems);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    } else {
      console.error("No file selected");
    }

    // Reset the input value to allow re-upload
    event.target.value = "";
  };
  const downloadChatHistory = async () => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseUrl}/chat-api/get-main-jounral`,
        data: { id: activeConversation, token },
        headers: {
          "Content-Type": "application/json",
          authtoken: token,
        },
      });
      // setmainjounral from localstorge
      localStorage.setItem("mainJounralId", data?.data?.mainJounralId);
      const newMessages = data.data?.map((item) => ({
        ...item,
        _id: item._id["$oid"],
        conversation_id: item.conversation_id["$oid"],
        history: JSON.parse(item.history),
      }));
      const history = newMessages.map((item) => item.history);
      // Convert history array to a JSON string
      const jsonString = JSON.stringify(history, null, 2);

      // Create a Blob from the JSON string
      const blob = new Blob([jsonString], { type: "application/json" });

      // Create a link element
      const link = document.createElement("a");

      // Set the download attribute with a filename
      link.download = `chat_history_${activeConversation}.json`;

      // Create a URL for the Blob and set it as the href attribute
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (err) {
      console.log("Error inside downloadChatHistory", err);
      throw err;
    }
  };
  const updateMessage = async (msg) => {
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}/chat-api/update-message-history`,
      headers: {
        "Content-Type": "application/json", // Example of a header
        authtoken: token,
      },
      data: {
        token: token,
        ...msg,
      },
    });
    setMessages(
      data?.data?.map((item) => {
        return {
          ...item,
          _id: item._id["$oid"],
          conversation_id: item.conversation_id["$oid"],
          history: JSON.parse(item.history),
        };
      })
    );
  };
  const uploadMessage = async (message_history) => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseUrl}/chat-api/upload-message-history`,
        headers: {
          "Content-Type": "application/json", // Example of a header
          authtoken: token,
        },
        data: {
          conversation_id: activeConversation,
          token: token,
          message_history,
        },
      });
      setMessages(
        data?.data?.map((item) => {
          return {
            ...item,
            _id: item._id["$oid"],
            conversation_id: item.conversation_id["$oid"],
            history: JSON.parse(item.history),
          };
        })
      );
      setUploadLoading(false);
    } catch (error) {
      console.log("Error inside uploadMessage", error);
      setUploadLoading(false);
    }
  };

  const updateFavoriteJournal = async () => {
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}/chat-api/isFavoriteJournal`,
      headers: {
        "Content-Type": "application/json", // Example of a header
        authtoken: token,
      },
      data: {
        conversation_id: activeConversation,
        token: token,
        isFavoriteJournal: !isFavoriteJournal,
      },
    });
    const listData = data.filter(
      (obj) => obj._id["$oid"] === activeConversation
    );
    const isFav =
      listData.length > 0 ? listData[0]?.isFavoriteJournal || false : false;
    setIsFavoriteJournal(isFav);
    dispatch(setReduxIsFavoriteJournal(isFav));
  };

  const fetchConversation = async (newPage = page, isAutoScrollEnable) => {
    const chatContainer = document.getElementById("chat-container");
    const previousScrollHeight = chatContainer.scrollHeight;
    const previousScrollTop = chatContainer.scrollTop;
    if (token && hasMore && !loadingMore) {
      isAutoScrollEnable && setIsAutoReload(true);
      setLoadingMore(true); // Ensure we only fetch if not already loading
      try {
        const { data } = await axios({
          method: "POST",
          url: `${baseUrl}/chat-api/get-main-jounral`,
          data: { page: newPage },
          headers: {
            "Content-Type": "application/json",
            authtoken: token,
          },
        });
        localStorage.setItem("mainJounralId", data?.mainJounralId);
        const listData = conversationList.filter(
          (obj) => obj._id["$oid"] === activeConversation
        );
        setActiveConversation(data?.mainJounralId);
        dispatch(setActiveConversationId(data?.mainJounralId));
        const isFav =
          listData.length > 0 ? listData[0]?.isFavoriteJournal || false : false;
        setIsFavoriteJournal(isFav);
        dispatch(setReduxIsFavoriteJournal(isFav));

        const newMessages = data.data?.map((item) => ({
          ...item,
          _id: item._id["$oid"],
          conversation_id: item.conversation_id["$oid"],
          history: JSON.parse(item.history),
        }));
        setMessages((prevMessages) =>
          newPage === 1 ? newMessages : [...newMessages, ...prevMessages]
        );

        setHasMore(
          newMessages.length > 0 && messages.length < data.totalRecords
        );
        // Adjust the scroll position after new messages are added
        setTimeout(() => {
          const newScrollHeight = chatContainer.scrollHeight;
          const scrollDiff = newScrollHeight - previousScrollHeight;
          chatContainer.scrollTop = previousScrollTop + scrollDiff;
          isAutoScrollEnable && scrollToBottom();
        }, 100);
      } catch (error) {
        console.error("Error fetching conversation:", error);
        if (error?.response?.data?.message === "Token is invalid!") {
          auth.logout();
        }
      } finally {
        setLoadingMore(false);
        setIsAutoReload(false);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const chatContainer = document.getElementById("chat-container");
      if (chatContainer) {
        const scrollTop = chatContainer.scrollTop;
        if (scrollTop === 0 && !loadingMore && hasMore) {
          setPage((prevPage) => prevPage + 1); // Trigger loading more messages
        }
      }
    };
    function debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    }
    const debounceHandleScroll = debounce(handleScroll, 300); // Adjust the debounce time as needed

    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.addEventListener("scroll", debounceHandleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", debounceHandleScroll);
      }
    };
  }, [loadingMore, hasMore]);

  useEffect(() => {
    fetchConversation(page);
  }, [page, hasMore, token]);

  useEffect(() => {
    if (newMessage) {
      scrollToBottom();
      setNewMessage(false);
    }
  }, [newMessage]);
  useEffect(() => {
    const inputElement = document.getElementById("prompt-input");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          if (
            (longitude && userInfo?.location?.longitude !== longitude) ||
            (latitude && userInfo?.location.latitude !== latitude)
          ) {
            onUpdateLocation({ latitude, longitude });
          }
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        if (permissionStatus.state === "granted") {
          requestLocation();
        } else if (permissionStatus.state === "prompt") {
          requestLocation();
        } else if (permissionStatus.state === "denied") {
          setError(
            "Location access is denied. Please enable it in browser settings."
          );
          !sessionStorage.getItem("locationStatus") &&
            alert(
              "Location permission was denied. Please reset the location permission in your browser settings to continue using this feature."
            );
          sessionStorage.setItem("locationStatus", true);
        }
      });
  }, []);

  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      const isAtTop = chatContainer.scrollTop === 0;
      const isAtBottom =
        chatContainer.scrollTop + chatContainer.clientHeight >=
        chatContainer.scrollHeight;
      setIsAtTop(isAtTop);
      setIsAtBottom(isAtBottom);
    }
  };
  const onUpdateLocation = async (location) => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseUrl}/chat-api/location`,
        headers: {
          "Content-Type": "application/json",
          authtoken: token,
        },
        data: {
          token: token,
          location,
        },
      });
      if (data?.data) {
        localStorage.setItem("userInfo", JSON.stringify(data?.data));
      }
    } catch (error) {
      console.log("Error inside in onUpdateLocation", error);
    }
  };
  function isIphone() {
    return /iPhone/.test(navigator.userAgent) && !window.MSStream;
  }

  const handleFocus = () => {
    !selectedChatMenu && setSelectedChatStatus(true);
  };

  return (
    <div
      className={`flex flex-1 overflow-y-auto overflow-x-hidden chat-history-wrapper ${
        isIphone() ? "iphone-chat-wrapper" : ""
      }`}
    >
      <div className="flex flex-col flex-1 bg-[white] my-1 lg:ml-4 lg:mr-0 lg:mr-4  rounded-2xl lg:rounded-s-2xl  border-r border-gray-300 chat-wrapper">
        <Header
          activeModel={activeModel}
          handleModelChange={handleModelChange}
          messages={messages}
          updateFavoriteJournal={updateFavoriteJournal}
          downloadChatHistory={downloadChatHistory}
          handleIconClick={handleIconClick}
          uploadHistory={uploadHistory}
          setFileInput={setFileInput}
          isFavoriteJournal={isFavoriteJournal}
          uploadLoading={uploadLoading}
        />
        <main
          ref={chatContainerRef}
          id="chat-container"
          className="flex-1 overflow-y-auto paragraph lg:px-4 px-0"
        >
          {!isAtTop && (
            <DoubleLeftOutlined
              className="scroll-chat-btn "
              style={{ rotate: "90deg", top: "80px" }}
              onClick={() => scrollToTop()}
            />
          )}
          {!isAutoReload ? (
            <ReloadOutlined
              className={`scroll-chat-btn ${
                isIphone()
                  ? "scroll-chat-reload-btn-iphone"
                  : "scroll-chat-reload-btn"
              }`}
              style={{ color: "rgb(34 139 34)", rotate: "-90deg" }}
              onClick={() => fetchConversation(1, true)}
            />
          ) : (
            <LoadingOutlined
              className={`scroll-chat-btn ${
                isIphone()
                  ? "scroll-chat-reload-btn-iphone"
                  : "scroll-chat-reload-btn"
              }`}
              style={{
                color: "rgb(34 139 34)",
                rotate: "-90deg",
                background: "transparent",
              }}
            />
          )}
          {!isAtBottom && (
            <DoubleLeftOutlined
              className={`scroll-chat-btn ${
                isIphone() ? "scroll-chat-btn-iphone" : "scroll-chat-bottom-btn"
              }`}
              style={{ rotate: "-90deg" }}
              onClick={() => scrollToBottom()}
            />
          )}

          <>
            {loadingMore && (
              <div className="flex justify-center my-4">
                <Spin />
              </div>
            )}
            <Messages
              messages={messages}
              loading={loading}
              regenrateMsg={regenrateMsg}
              updateMessage={updateMessage}
              regenrateResponse={() => regenrateResponse()}
              setAIPromptTemplate={(templateMsg) => {
                if (templateMsg.trim() !== "") {
                  onSendMessage(templateMsg);
                  setPrompt("");
                  setPrevPrompt(templateMsg);
                }
              }}
            />
          </>
        </main>
        <footer className="lg:p-4 p-0 m-4  ">
          {isActive && (
            <div className="hidden lg:flex items-center justify-center mb-10">
              <div className="flex items-center justify-center">
                <div className="flex items-center space-x-2">
                  <div className="flex space-x-1">
                    <div
                      className="w-2 h-6 bg-red-500 rounded-full animate-recording"
                      style={{ animationDelay: "0s" }}
                    ></div>
                    <div
                      className="w-2 h-6 bg-red-500 rounded-full animate-recording"
                      style={{ animationDelay: "0.2s" }}
                    ></div>
                    <div
                      className="w-2 h-6 bg-red-500 rounded-full animate-recording"
                      style={{ animationDelay: "0.4s" }}
                    ></div>
                  </div>
                  <span className="text-gray-700">Recording...</span>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-row gap-2 items-center w-full justify-between">
            <div className="lg:flex hidden items-center gap-2 w-full">
              <div
                onClick={() => {
                  handleOnRecord();
                }}
                style={{
                  pointerEvents: loading || uploadLoading ? "none" : "auto",
                }}
                className={`cursor-pointer bg-[#f7f7f7] p-4 text-2xl text-gray-500 rounded-2xl ${
                  isActive ? "animate-pulse" : ""
                }`}
              >
                <AudioOutlined />
              </div>
              <div
                className={`flex-1 flex gap-3  bg-[#f7f7f7] border border-gray-300 rounded-2xl ${
                  !loading || uploadLoading ? " input-highlight " : ""
                }`}
              >
                <textarea
                  type="text"
                  rows="1"
                  value={prompt}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setPrompt(inputValue);
                  }}
                  onKeyDown={handleKeyDown}
                  onFocus={() => handleFocus()}
                  onInput={handleInput}
                  placeholder="Start typing"
                  id="prompt-input" // Add an ID to the input element
                  className="w-full bg-[#f7f7f7] ml-2 border-none outline-none text-md text-gray-800 p-4"
                  style={{ maxHeight: "150px" }} // Set max height to 200px
                />
                <img
                  src="/voice.png"
                  onClick={() =>
                    navigate(
                      `/${selectedChatMenu || "General Entry"}/voice-assistance`
                    )
                  }
                  className="rounded-full cursor-pointer h-[30px] w-[30px] m-auto mr-3 border border-gray-300 p-1 border-[1px] "
                />
              </div>

              <div className="bg-[#168900] p-4 text-2xl text-white rounded-2xl ">
                {loading || uploadLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: "24px", color: "white" }}
                  />
                ) : (
                  <span
                    className="cursor-pointer"
                    onClick={() => sendMessage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-send"
                    >
                      <path d="m22 2-7 20-4-9-9-4Z" />
                      <path d="M22 2 11 13" />
                    </svg>
                  </span>
                )}
              </div>
            </div>

            <div
              className={`flex w-full lg:hidden items-center border border-gray-300 rounded-2xl p-2 ${
                !loading || uploadLoading ? "input-highlight" : ""
              }`}
            >
              {isActive ? (
                <div className="flex items-center w-full gap-2 justify-end">
                  <div className="flex space-x-1 items-center">
                    {audioLevels.map((level, index) => (
                      <div
                        key={index}
                        className={`w-0.5 bg-[#168900] rounded-full animate-recording`}
                        style={{
                          height: `${level * 3}px`,
                          animationDelay: `${index * 0.1}s`,
                        }}
                      ></div>
                    ))}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="text-[#168900]">{stopwatch.toFixed(1)}</div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row w-full">
                  <textarea
                    type="text"
                    value={prompt}
                    rows="1"
                    onInput={handleInput}
                    onFocus={() => handleFocus()}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setPrompt(inputValue);
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Start typing"
                    id="prompt-input" // Add an ID to the input element
                    className={`flex-1 max-h-10 bg-transparent border-none overflow-y-auto outline-none p-2 text-md text-gray-800 `}
                    style={{ maxHeight: "150px" }} // Set max height to 200px
                  />

                  <div className="flex flex-row">
                    <div
                      onClick={() => {
                        handleOnRecord();
                      }}
                      className={`cursor-pointer flex items-center justify-center bg-[#168900] rounded-full w-8 h-8 mr-2  ${
                        isActive ? "animate-pulse" : ""
                      }`}
                      style={{
                        pointerEvents:
                          loading || uploadLoading ? "none" : "auto",
                      }}
                    >
                      <AudioFilled
                        style={{ fontSizez: "24px", color: "white" }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!isActive && (
              <div
                onClick={sendMessage}
                className="cursor-pointer flex lg:hidden  items-center justify-center bg-[#168900] rounded-full  w-10 h-10 p-2"
                style={{
                  pointerEvents: loading || uploadLoading ? "none" : "auto",
                }}
              >
                {loading || uploadLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: "24px", color: "white" }}
                  />
                ) : (
                  <div className="">
                    <ArrowUpOutlined
                      style={{ fontSizez: "24px", color: "white" }}
                    />
                  </div>
                )}
              </div>
            )}
            {selectedChatStatus && (
              <ChatMenuModal
                isModalOpen={true}
                onSelectMenuBtnClick={(e) => {
                  setSelectedChatMenu(e);
                  e !== "General Entry" && onSendMessage(e);
                  setSelectedChatStatus(false);
                }}
                onCancel={() => setSelectedChatStatus(false)}
              />
            )}
          </div>
          <div className="flex items-center justify-center text-gray-400 text-sm mt-2">
            Free research view.Journal assistant may display inaccurate
            info.Check important info.
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Chat;
