/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import betaImage from "../../assets/betaImage.svg";
import axios from "axios";
import { notification } from "antd";

// import { openNotificationWithIcon } from '../../utils/helpers';
// import { axiosAPICall } from '../../common/apiCallService';
// import { HanselPrivateRoutes } from '../../lib/routes';

import "./signupLoginstyle.css";
import { baseUrl } from "../../utils/baseUrl";

const Logo = () => (
  <Link
    to="/"
    className="cursor-pointer flex items-center animated"
    id="main ani-1"
  >
    <div className="relative inline-flex gap-2 items-center p-3 text-sm font-medium text-center ">
      <img
        src={logo}
        alt="Logo"
        align="middle"
        // width="32px"
        // height="32px"
        className=""
      />
      {/* <h1 className="text-grey-1 font-bold lg:text-2xl md:text-lg sm:text-lg tracking-tight">
      Journal assistant
      </h1> */}
      {/* <div className="absolute inline-flex items-center justify-center w-16 h-16  -top-8 -end-8">
        {" "}
        <img src={betaImage} alt="beta" className="absolute " />
      </div> */}
    </div>
  </Link>
);

const SignUpLogin = ({ signupLogin }) => {
  const [activeScreen, setActiveScreen] = useState(signupLogin);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [termsError, setTermsError] = useState(false)
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  useEffect(() => {
    if (
      nameError ||
      emailError ||
      passwordError ||
      !name ||
      !email ||
      !password ||
      !termsError
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [nameError, emailError, passwordError, name, email, password, termsError]);

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description,
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    return (
      password.length >= minLength &&
      hasNumber.test(password) &&
      hasSpecialChar.test(password)
    );
  };
  const handleSignUpLogIn = async (buttonState) => {
    let valid = true;
    if (buttonState === "SignUp") {
      if (!name) {
        setNameError("Name cannot be empty");
        valid = false;
      }
      if (!isTermsAccepted) {
        setTermsError("Please accept Terms and Conditions");
        valid = false;
      }
      else {
        setNameError("");
        setTermsError("")
      }
    }

    if (!email) {
      setEmailError("Email cannot be empty");
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and contain a number and a special character"
      );
      valid = false;
    } else {
      setPasswordError("");
    }
    if (valid) {
      try {
        const { data } =
          buttonState === "SignIn"
            ? await axios({
              url: `${baseUrl}/chat-api/signin`,
              data: { email, password },
              method: "post",
            })
            : await axios({
              url: `${baseUrl}/chat-api/signup`,
              data: { name, email, password },
              method: "post",
            });
        if (data?.status === 200) {
          localStorage.setItem("token", data?.data?.token || data?.token);
          const userDtls = data?.data || data;
          localStorage.setItem("userInfo", JSON.stringify(userDtls));
          window.location.href = "/shkale/chat";
          // navigate("/shkaleChat");
        }
        if (data?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Error",
            data?.error || data?.err || data?.message
          );
        }
      } catch (error) {
        console.log("error----", error);
        openNotificationWithIcon(
          "error",
          "Error",
          error?.response?.data?.error ||
          error?.response?.data?.err ||
          error?.response?.data?.message
        );
      }
    }
  };
  const handleChange = (event) => {
    setIsTermsAccepted(event.target.checked);
    event.target.checked && setTermsError("");
  };
  return (
    <div
      className={`signupContainer  ${activeScreen === "SignUp" ? " " : "active"
        }`}
      id="signupContainer"
    >
      <div className="form-signupContainer sign-up lg:w-6/12 w-full">
        <form>
          <Logo />
          <h1>Create Account</h1>

          <input
            type="text"
            placeholder="Name"
            value={name}
            className="w-full"
            onChange={(e) => {setName(e.target.value),setNameError("")}}
          />
          {nameError && <div className="error text-red-500 text-xs">{nameError}</div>}
          <input
            type="email"
            placeholder="Email"
            value={email}
            className="w-full"
            onChange={(e) => {setEmail(e.target.value),setEmailError("")}}
          />
          {emailError && <div className="error text-red-500 text-xs">{emailError}</div>}
          <input
            type="password"
            placeholder="Password"
            className="w-full"
            value={password}
            onChange={(e) => {setPassword(e.target.value),setPasswordError("")}}
          />
          {passwordError && (
            <div className="error text-red-500 text-xs">{passwordError}</div>
          )}
          <div className="flex gap-3">
            <input
              type="checkbox"
              className={`h-4 w-4  ${termsError ? 'border-red-500' : ''}`}
              id="isTermsAccepted"
              value={isTermsAccepted}
              onChange={handleChange}
              defaultChecked={isTermsAccepted}
            />
            <label className="mt-1 text-xs" htmlFor="isTermsAccepted">
              By ticking this box you agree to our&nbsp;
              <a href="/terms-and-conditons" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                terms of use
              </a>
              &nbsp;and consent to our use of your personal information, in line with our&nbsp;
              <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                privacy and data protection policy.
              </a>
            </label>
          </div>
          {termsError && <div className="text-red-500 mt-2 text-xs">{termsError}</div>}
          <button type="button" onClick={() => handleSignUpLogIn("SignUp")}>
            Sign Up
          </button>
          <div>
            Already have an account?{" "}
            <span onClick={() => setActiveScreen("SignUp")}>Login</span>
          </div>
        </form>
      </div>
      <div className="form-signupContainer sign-in lg:w-6/12 w-full">
        <form>
          <Logo />
          <input
            type="email"
            placeholder="Email"
            value={email}
            className="w-full"
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <div className="error text-red">{emailError}</div>}
          <input
            type="password"
            placeholder="Password"
            className="w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <div className="error text-red">{passwordError}</div>
          )}
          <div className="forgot-password-container">
            <a href="#" className="forgot-password-link">
              Forget Your Password?
            </a>
          </div>

          <button type="button" onClick={() => handleSignUpLogIn("SignIn")}>
            Sign In
          </button>
          <button
            type="button"
            onClick={() => setActiveScreen("Login")}
            style={{
              backgroundColor: "#00000000",
              border: "1px solid #228B22",
            }}
          >
            Sign Up
          </button>
        </form>
      </div>
      <div className="lg:block hidden toggle-signupContainer">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1 className="text-3xl">Welcome Back!</h1>
            <p>Enter your personal details to use all of site features</p>
            <button
              className={`${activeScreen === "Login" ? "" : "active"}`}
              onClick={() => {
                setEmailError("");
                setNameError("");
                setPasswordError("");
                setActiveScreen("SignUp");
              }}
              id="login"
            >
              Sign In
            </button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1 className="text-3xl">Hello, Friend!</h1>
            <p>
              Register with your personal details to use all of the site
              features
            </p>
            <button
              className={`${activeScreen === "SignUp" ? "" : "active"}`}
              onClick={() => {
                setEmailError("");
                setNameError("");
                setPasswordError("");
                setActiveScreen("Login");
              }}
              id="register"
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpLogin;
