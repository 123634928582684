/* eslint-disable no-nested-ternary */
import React from 'react';
import auth from './auth';
import { Navigate, Outlet } from 'react-router-dom';
import Menu from '../Components/Menu/Menu';

const AdminPrivateRoute = () => {
    return auth.isAuthenticated ?
        <div className='flex h-screen bg-[black]'>
            <Menu />
            <div className='admin-content-wrapper'>
            <Outlet />
            </div>
        </div>
        : <Navigate to="/" />;

};

export default AdminPrivateRoute;
