import React from 'react'

function TermsCondition() {
    return (

        <div className='bg-[gray]'>
            <div className='p-8 m-auto border-[1px]  w-8/12 bg-[white]'>
                <div className="container mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
                    <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
                    <p className="text-gray-600 mb-4">Effective Date: {new Date().toDateString()}</p>
                    <p className="text-gray-600 mb-4">Welcome to the Skhale App (hereafter referred to as “the App”). By downloading, accessing, or using Skhale, you agree to be bound by these Terms and Conditions (the “Terms”). If you do not agree to these Terms, please do not use the App.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">1. Acceptance of Terms</h2>
                    <p className="text-gray-600 mb-4">By using the App, you agree to abide by these Terms and any other policies referenced herein. These Terms apply to all users, including visitors, registered users, and anyone who accesses or uses the App.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">2. Changes to Terms</h2>
                    <p className="text-gray-600 mb-4">We may update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting the updated Terms in the App. Your continued use of the App after any changes signifies your acceptance of the new Terms. We encourage you to review these Terms periodically.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">3. Use of the App</h2>
                    <p className="text-gray-600 mb-4">You agree to use the App only for lawful purposes and in compliance with these Terms. Specifically, you agree that you will not:</p>
                    <ul className="list-disc list-inside text-gray-600 mb-4">
                        <li>Use the App in any way that violates any applicable local, national, or international law.</li>
                        <li>Use the App for any unauthorized, deceptive, or fraudulent purposes.</li>
                        <li>Attempt to gain unauthorized access to our systems or other users’ accounts.</li>
                        <li>Engage in any activity that disrupts or interferes with the App's functionality or security.</li>
                    </ul>

                    <h2 className="text-2xl font-bold mt-6 mb-2">4. Registration and Account Security</h2>
                    <p className="text-gray-600 mb-4">In order to access certain features of the App, you may be required to create an account. You agree to provide accurate, complete, and current information during registration. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                    <p className="text-gray-600 mb-4">If you believe that your account has been compromised, please notify us immediately at [Contact Information].</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">5. User Content</h2>
                    <p className="text-gray-600 mb-4">You may be able to post, upload, or otherwise submit content (e.g., comments, feedback, images) in the App (collectively “User Content”). By submitting User Content, you grant Skhale a non-exclusive, royalty-free, worldwide, and perpetual license to use, display, modify, and distribute your content as necessary to operate and improve the App.</p>
                    <p className="text-gray-600 mb-4">You represent and warrant that:</p>
                    <ul className="list-disc list-inside text-gray-600 mb-4">
                        <li>You own or have the necessary rights to the User Content you submit.</li>
                        <li>Your User Content does not infringe the rights of any third party, including intellectual property rights.</li>
                        <li>Your User Content does not contain any unlawful, harmful, or inappropriate content.</li>
                    </ul>
                    <p className="text-gray-600 mb-4">We reserve the right to remove any User Content that violates these Terms or is deemed inappropriate.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">6. Intellectual Property</h2>
                    <p className="text-gray-600 mb-4">The App and all content, features, and functionality (including text, graphics, logos, icons, images, audio clips, digital downloads, and software) are owned by Skhale or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
                    <p className="text-gray-600 mb-4">You are granted a limited, non-exclusive, and revocable license to use the App for personal, non-commercial purposes. You agree not to copy, modify, distribute, sell, or lease any part of the App without our written permission.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">7. Payment and Subscriptions</h2>
                    <p className="text-gray-600 mb-4">Certain features of the App may require payment or subscription fees. By opting for paid features, you agree to:</p>
                    <ul className="list-disc list-inside text-gray-600 mb-4">
                        <li>Provide accurate billing information and pay all associated fees.</li>
                        <li>Comply with any additional terms specific to paid features, which will be clearly presented at the time of purchase.</li>
                    </ul>
                    <p className="text-gray-600 mb-4">We reserve the right to change our pricing or subscription terms at any time. Any changes will apply only to future transactions, and we will notify you in advance of any price changes.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">8. Privacy Policy</h2>
                    <p className="text-gray-600 mb-4">Your use of the App is also governed by our Privacy Policy. Please review it to understand our practices regarding the collection, use, and protection of your personal information.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">9. Termination</h2>
                    <p className="text-gray-600 mb-4">We reserve the right to terminate or suspend your account and access to the App at our discretion, without notice, for any reason, including but not limited to your violation of these Terms. You may also terminate your account by contacting us at [Contact Information].</p>
                    <p className="text-gray-600 mb-4">Upon termination, you must discontinue all use of the App and delete any copies of the App on your device. We reserve the right to retain certain information as required by law or as outlined in our Privacy Policy.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">10. Disclaimer of Warranties</h2>
                    <p className="text-gray-600 mb-4">The App is provided on an "as-is" and "as-available" basis. We make no warranties, whether express or implied, regarding the reliability, accuracy, or suitability of the App for any purpose. We do not guarantee that the App will be error-free, secure, or available at all times.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">11. Limitation of Liability</h2>
                    <p className="text-gray-600 mb-4">To the fullest extent permitted by law, Skhale and its affiliates, officers, directors, employees, and agents will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from or related to your use or inability to use the App. This includes damages resulting from any loss of data, profits, or reputation.</p>
                    <p className="text-gray-600 mb-4">If you are dissatisfied with the App, your sole remedy is to discontinue its use.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">12. Indemnification</h2>
                    <p className="text-gray-600 mb-4">You agree to indemnify, defend, and hold harmless Skhale and its affiliates, officers, directors, employees, and agents from any claims, damages, losses, liabilities, and expenses arising from your use of the App, violation of these Terms, or infringement of any third-party rights.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">13. Governing Law and Dispute Resolution</h2>
                    <p className="text-gray-600 mb-4">These Terms are governed by the laws of [Your Jurisdiction] without regard to its conflict of law provisions. Any disputes arising from these Terms or your use of the App shall be resolved exclusively in the courts of [Your Jurisdiction].</p>
                    <p className="text-gray-600 mb-4">If you are using the App from outside [Your Jurisdiction], you are responsible for compliance with any local laws applicable to your use of the App.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">14. Severability</h2>
                    <p className="text-gray-600 mb-4">If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be fully effective and enforceable.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">15. Entire Agreement</h2>
                    <p className="text-gray-600 mb-4">These Terms constitute the entire agreement between you and Skhale regarding your use of the App, superseding any prior agreements or communications.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">16. Contact Us</h2>
                    <p className="text-gray-600 mb-4">If you have any questions or concerns about these Terms, please contact us at:</p>
                    <p className="text-gray-600 mb-4">Email: [Your Email Address]</p>
                    <p className="text-gray-600 mb-4">Address: [Your Business Address]</p>
                    <p className="text-gray-600 mb-4">Phone: [Your Phone Number]</p>

                    <h2 className="text-2xl font-bold mt-6 mb-2">17. Additional Terms for Specific Features</h2>
                    <p className="text-gray-600 mb-4">Some features of the App may be subject to additional terms, which will be presented to you when accessing those features. By using those features, you agree to any applicable additional terms.</p>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition