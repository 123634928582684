import { createSlice } from '@reduxjs/toolkit';

import { apiCallBegan } from '../api';

const initialState = {
  isLoadingRequestIds: [],
  mainJournal:[],
  todaysHistory: [],
  yesterdaysHistory: [],
  last7DaysHistory: [],
  activeConversation: '',
  conversationList:[],
  isFavoriteJournal:false
};

const formatDate = date => date.toISOString().slice(0, 10);

const filterByDate = (data, date) => {
  const updatedData = data.map(item => {
    return {
      ...item,
      _id: item._id.$oid,
      user: item.user.$oid,
      createdAt: new Date(item.createdAt.$date)
    };
  });
  return updatedData.filter(item => formatDate(new Date(item.createdAt)) === formatDate(date));
}
const formattedData = (data, date) => {  
  const updatedData = data?.map(item => {
    return {
      ...item,
      _id: item._id.$oid,
      user: item.user.$oid,
      createdAt: new Date(item.createdAt.$date)
    };
  });
  return updatedData
}


const last7DaysAndBeyond = (data, today, yesterday) => {
  const updatedData = data.map(item => {
    return {
      ...item,
      _id: item._id.$oid,
      user: item.user.$oid,
      createdAt: new Date(item.createdAt.$date)
    };
  });

  const last7DaysRecords = updatedData.filter(item => {
    const createdDate = new Date(item.createdAt);
    const diff = today - createdDate;
    return (
      diff > 0 &&
      diff <= 7 * 24 * 60 * 60 * 1000 &&
      formatDate(createdDate) !== formatDate(today) &&
      formatDate(createdDate) !== formatDate(yesterday)
    );
  });

  const beyond7DaysRecords = updatedData.filter(item => {
    const createdDate = new Date(item.createdAt);
    const diff = today - createdDate;
    return (
      diff > 7 * 24 * 60 * 60 * 1000 &&
      item.name && item.name.trim() !== ''
    );
  });

  return [...last7DaysRecords, ...beyond7DaysRecords];
};

export const hanselChatSlice = createSlice({
  name: 'HanselChat',
  initialState,
  reducers: {
    apiRequested: (state, action) => {
      const isLoadingRequestIds = [...state.isLoadingRequestIds];
      if (action.loadingRequire) {
        state.isLoadingRequestIds = [...isLoadingRequestIds, action.requestedId];
      }
    },
    apiRequestFailed: (state, action) => {
      const isLoadingRequestIds = [...state.isLoadingRequestIds];
      state.isLoadingRequestIds = isLoadingRequestIds.filter(value => value !== action.requestedId);
    },
    getUsers: (state, action) => {
      state.adminUsers = action.payload;
      const isLoadingRequestIds = [...state.isLoadingRequestIds];
      state.isLoadingRequestIds = isLoadingRequestIds.filter(value => value !== action.requestedId);
    },
    listConversations: (state, action) => {
      const data = action.payload.map(item => ({ ...item, isEditing: false }));
      const updatedData = data?.filter(item=>item.name !== "Main Journal")
      const mainJournalHistory = data.filter(item=>item.name === "Main Journal")
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      state.conversationList=data
      state.mainJournalHistory = formattedData(mainJournalHistory);
      // state.todaysHistory = filterByDate(updatedData, today);
      // state.yesterdaysHistory = filterByDate(updatedData, yesterday);
      // state.last7DaysHistory = last7DaysAndBeyond(updatedData, today, yesterday);
      const isLoadingRequestIds = [...state.isLoadingRequestIds];
      state.isLoadingRequestIds = isLoadingRequestIds.filter(value => value !== action.requestedId);
    },
    setActiveConversationId: (state, action) => {
      state.activeConversation = action.payload;
    },
    setReduxIsFavoriteJournal: (state, action) => {
      state.isFavoriteJournal= action.payload;
    },
    updateSessionName: (state, action) => {
      const { entity, newEditStates } = action.payload;
      state[entity] = newEditStates;
    },
  },
});

export const {
  listConversations,
  apiRequested,
  apiRequestFailed,
  setActiveConversationId,
  updateSessionName,
  setReduxIsFavoriteJournal,
} = hanselChatSlice.actions;

export default hanselChatSlice.reducer;

export const listUserConversationsApi = data =>
  apiCallBegan({
    url: '/list-conversations',
    method: 'post',
    data,
    requestedId: 'List-Conversations',
    onStart: apiRequested.type,
    onSuccess: listConversations.type,
    onError: apiRequestFailed.type,
  });
