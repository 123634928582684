
import { combineReducers } from 'redux';

import { configReducer } from './configReducer';

import shkaleChatSlice from './shkaleChatSlice';

export default combineReducers({
  config: configReducer,

  shkaleChat: shkaleChatSlice,
});
