import Navbar from "../../Components/Navbar/Navbar";
import Hero from "../../Components/Hero/Hero";
import Features from "../../Components/Features/Features";
import Title from "../../Components/Title/Title";
import Footer from "../../Components/Footer/Footer";
import { ModalProvider } from "../../Components/ModalCOntext";

const App = () => {
  return (
    <div className="">
      <ModalProvider>
        <Navbar />
        <Hero />
      </ModalProvider>

      <div className="container">
        <Title subTitle="Features" title="What We Offer" />
        <Features />

        <Footer />
      </div>
    </div>
  );
};

export default App;
